<pwc-modal #attachmentModal id="attachment-modal" [dismissable]="false" [escapable]="false">
    <div modal-title>
        <img src="/assets/images/icons/documents/toolbar/paper-clip.svg" [alt]="'connection.modal.link'|translate"
            style="width: 40px;height: 40px;"> {{'generic.action.attach'|translate}}
    </div>

    <div modal-body>
        <div class="card-table light" *ngIf="items.length > 0">
            <data-table #dataTable [dataSource]="items" [customColumnDefs]="customColumns" [hoverable]="false"
                [customColumns]="customColumnsActive" [detailRowTpl]="metadataTpl">
                <ng-container cdkColumnDef="name">
                    <th cdk-header-cell *cdkHeaderCellDef scope="col">{{'generic.field.name'|translate}}</th>
                    <td cdk-cell *cdkCellDef="let row">
                        {{row.item?.file?.name}}
                    </td>
                </ng-container>

                <ng-container cdkColumnDef="fiscalYear">
                    <th cdk-header-cell *cdkHeaderCellDef scope="col">{{'generic.field.fiscal-year'|translate}}</th>
                    <td cdk-cell *cdkCellDef="let row" class="one-file-selector">
                        <ng-select id="fiscalYear" bindLabel="year" bindValue="year" appendTo="body" style="width: 90px"
                            [(ngModel)]="row.fiscalYear" (change)="onFiscalYearChange(row)">
                            <ng-option *ngFor="let year of yearsList" [value]="year.year">
                                {{year.year}}
                            </ng-option>
                        </ng-select>
                    </td>
                </ng-container>

                <ng-container cdkColumnDef="parent">
                    <th cdk-header-cell *cdkHeaderCellDef scope="col">{{'generic.field.path'|translate}}</th>
                    <td cdk-cell *cdkCellDef="let row" class="one-file-selector">
                        <ng-select id="parentId" [(ngModel)]="row.parentId" class="is-search" appendTo="body"
                            (change)="onFolderSelected(row)" (search)="searchFolder(row, $event)"
                            [items]="row.searchList" bindValue="id" bindLabel="path"
                            [disabled]="row.parentList.length == 0" style="width: 228px">
                            <ng-template ng-option-tmp let-item="item">
                                <div [title]="item.path+'/'+item.name">{{item.path}}/{{item.name}}</div>
                            </ng-template>
                        </ng-select>
                    </td>
                </ng-container>
                <ng-container cdkColumnDef="loader">
                    <th cdk-header-cell *cdkHeaderCellDef scope="col"></th>
                    <td cdk-cell *cdkCellDef="let row" id="folder-loader">
                        <fa-icon [icon]="faCircleNotch"
                            *ngIf="loadingParentFolders.index == row.index && loadingParentFolders.loading == true"
                            [spin]="true" class="spinner"></fa-icon>
                    </td>
                </ng-container>
                <ng-container cdkColumnDef="actions">
                    <th cdk-header-cell *cdkHeaderCellDef scope="col"></th>
                    <td cdk-cell *cdkCellDef="let row">
                        <a href="javascript:;" (click)="row.expand = !row.expand" *ngIf="row.parentId">
                            <fa-icon [icon]="faCaretDown" *ngIf="!row.expand"></fa-icon>
                            <fa-icon [icon]="faCaretUp" *ngIf="row.expand"></fa-icon>
                        </a>
                    </td>
                </ng-container>
            </data-table>
        </div>
    </div>

    <div modal-footer>
        <button type="button" class="btn btn-default" (click)="close()">
            {{'generic.action.close'|translate}}
        </button>
        <button type="button" class="btn btn-danger ml-2" (click)="cancel()">
            {{'attachment.modal.cancel-attachment'|translate}}
        </button>
        <button type="button" class="btn btn-success ml-2" (click)="submit()" [disabled]="!isValid">
            {{'generic.action.confirm'|translate}}
        </button>
    </div>
</pwc-modal>

<ng-template #metadataTpl let-row>
    <tr class="cdk-row detail-row" [@detailExpand]="row.expand ? 'open' : 'close'"
        style="overflow: hidden;visibility:hidden" *ngIf="row.parentId">
        <td colspan="4">
            <app-attachment-metadata [fileName]="row.item?.file?.name" [fiscalYear]="row.fiscalYear"
                [metadata]="row.meta" (dateChange)="onDateChange(row, $event)"
                (fileNameChange)="onFileNameChange(row, $event)">
            </app-attachment-metadata>
        </td>
    </tr>
</ng-template>