// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    cookieDomain: 'pwc-tls.it',
    cookiePath: '/',
    secureFlag: true,
    authUrl: 'https://dev1.devds-backend.digitalsuite.pwc-tls.it', // framework
    apiUrl: 'https://dms-backend.devds.digitalsuite.pwc-tls.it', // backend
    communicoUrl: 'https://dev1.devds.digitalsuite.pwc-tls.it', // frontend FRAMEWORK
    idStringLength: 3,
    notificationInterval: 30, // in seconds
    showConservation: true, // show the conservation sidebar button
    infoSetInstanceUpload: {
        allowedMineType: [
            'text/xml',
            'application/excel',
            'application/x-excel',
            'application/vnd.ms-excel',
            'application/x-msexcel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ],
        maxFileSize: 1000 * 1024 * 1024
    },
    templates: {
        bilancioIVDirettiva: {
            filename: 'Template_bilancio_in_IV_direttiva_CEE.xlsx',
            url: '/assets/documents/Template_bilancio_in_IV_direttiva_CEE.xlsx'
        }
    },
    exportCsvRichEnabled: false,
    showPAurl: false,
    paUrl: ''
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
