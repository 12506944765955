import { CdkColumnDef } from '@angular/cdk/table';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  LOCALE_ID,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { Router } from '@angular/router';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { GeneralSettingValue, GeneralSettingValueService } from '@pwc/common';
import { piAdd, PwcIconsLibrary } from '@pwc/icons';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, first, map, switchMap, takeWhile, tap } from 'rxjs/operators';
import { isNull, isNullOrUndefined } from 'util';
import { NgbdSortableHeaderDirective, SortEvent } from '../../directives/common/sortable.directive';
import { SortDirectionEnum } from '../../enums/common/sort-direction.enum';
import { ConnectionElementType } from '../../enums/connection-element-type.enum';
import { DocumentTypeEnum } from '../../enums/document-type.enum';
import { ExportCsvType } from '../../enums/export-csv-type';
import { ErrorCode } from '../../enums/law-conservation/error-code.enum';
import { MetadataFieldType } from '../../enums/metadata-field-type.enum';
import { getIconByFileExtension, isBiggerThanOneGigabyte } from '../../helpers/common/generic-utils';
import { getMetadataFormattedValue, isBooleanMetadata } from '../../helpers/common/metadata-utils';
import { ToastUtils } from '../../helpers/toast-utils';
import { ConnectionElementEntryResource } from '../../models/api/connection/connection-element-entry-resource';
import { ConnectionElementResource } from '../../models/api/connection/connection-element-resource';
import { DmsDownloadElementResource } from '../../models/api/documents/dms-download-element-resource';
import { DmsElementMetadataRegResource } from '../../models/api/documents/dms-element-metadata-reg-resource';
import { DmsElementMoveResource } from '../../models/api/documents/dms-element-move-resource';
import { DmsElementResource } from '../../models/api/documents/dms-element-resource';
import { FiscalYearResource } from '../../models/api/fiscal-year-resource';
import { PageableResponse } from '../../models/api/pageable-response';
import { DmsSharingResource } from '../../models/api/share/dms-sharing-resource';
import { TagElementResource } from '../../models/api/tag/tag-element-resource';
import { TagResource } from '../../models/api/tag/tag-resource';
import { ColumnDef } from '../../models/common/data-table/column-def';
import { DmsDownloadElement } from '../../models/dms-download-element';
import { DmsElement } from '../../models/dms-element';
import { DmsElementMetadata } from '../../models/dms-element-metadata';
import { DmsElementMetadataReg } from '../../models/dms-element-metadata-reg';
import { DmsExportCsv } from '../../models/dms-export-csv';
import { DmsSharing } from '../../models/share/dms-sharing';
import { Sphere } from '../../models/sphere';
import { Tag } from '../../models/tag/tag';
import { SpinnerService } from '../../modules/spinner/services/spinner.service';
import { AttachmentCartService } from '../../services/attachment-cart.service';
import { ConnectionCartService } from '../../services/connection-cart.service';
import { DmsCartMoveService } from '../../services/dms-cart-move.service';
import { DmsCartService } from '../../services/dms-cart.service';
import { DmsFileRepositoryService } from '../../services/dms-file-repository.service';
import { DmsFolderRepositoryService } from '../../services/dms-folder-repository.service';
import { DmsMetadataService } from '../../services/dms-metadata.service';
import { DocumentLoaderService } from '../../services/document-loader.service';
import { DownloaderService } from '../../services/downloader.service';
import { LawConservationService } from '../../services/law-conservation/law-conservation.service';
import { SharingElementsService } from '../../services/share/sharing-elements.service';
import { SphereService } from '../../services/sphere.service';
import { TagService } from '../../services/tag.service';
import { UploaderService } from '../../services/uploader.service';
import { AttachmentModalComponent } from '../attachment/attachment-modal/attachment-modal.component';
import { AttachmentsUploaderComponent } from '../attachment/attachments-uploader/attachments-uploader.component';
import { StartAttachmentModalComponent } from '../attachment/start-attachment-modal/start-attachment-modal.component';
import { DataTableComponent } from '../common/data-table/data-table.component';
import { ModalComponent } from '../common/modals/modal/modal.component';
import { ConnectionModalComponent } from '../connection/connection-modal/connection-modal.component';
import { ConnectionTypeModalComponent } from '../connection/connection-type-modal/connection-type-modal.component';
import { StartConnectionModalComponent } from '../connection/start-connection-modal/start-connection-modal.component';
import { ExportCsvModalComponent } from '../export/export-csv-modal/export-csv-modal.component';
import { ShareModalComponent } from '../share-modal/share-modal.component';
import { AddTagModalComponent } from '../tags/add-tag-modal/add-tag-modal.component';
import { DocumentConfirmModalComponent } from './document-confirm-modal/document-confirm-modal.component';
import { DocumentDetailComponent } from './document-detail/document-detail.component';
import { DocumentFilterModalComponent } from './document-filter/document-filter-modal.component';
import { DocumentMetadataComponent } from './document-metadata/document-metadata.component';
import { DocumentsUploaderComponent } from './documents-uploader/documents-uploader.component';
import { DownloadModalComponent } from './download-modal/download-modal.component';
import { LawConservationModalComponent } from './law-conservation-modal/law-conservation-modal.component';
import { LawConservationSendNowModalComponent } from './law-conservation-send-now-modal/law-conservation-send-now-modal.component';
import { NewFolderModalComponent } from './new-folder-modal/new-folder-modal.component';
import { NoLawConservationModalComponent } from './no-law-conservation-modal/no-law-conservation-modal.component';
import { StartSharingModalComponent } from './start-sharing-modal/start-sharing-modal.component';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit, AfterViewChecked {

  data: DmsElement[] = [];
  pagination: PageableResponse<DmsElementResource[]>;
  columns: ColumnDef<DmsElement>[] = [];
  /*
  columns: ColumnDef<DmsElement>[] = [
    {
      field: 'type',
      header: '',
      cell: this.elementTypeCell,
      sortable: false,
      width: '30px',
      raw: true,
      onDblClick: (row: DmsElement) => this.openElement(row)
    }
  ];
  */
  customColumnsActive: string[] = [];
  customMetaColumnsActive: string[] = [];
  selectedResults: DmsElement[] = [];

  sort: Map<string, SortDirectionEnum> = new Map();
  tagSearch: boolean = false;
  searchTerm = '';
  searchSubject: Subject<string> = new Subject<string>();
  search$: Observable<string> = this.searchSubject.asObservable();

  loadingDocuments$: Observable<boolean> = this.documentLoaderService.loading$;

  deleting: boolean = false;

  path: DmsElement[] = [];

  yearsList: FiscalYearResource[] = [];
  selectedYear: FiscalYearResource;
  selectedYearSubject: BehaviorSubject<FiscalYearResource> = new BehaviorSubject<FiscalYearResource>(null);
  selectedYear$: Observable<FiscalYearResource> = this.selectedYearSubject.asObservable();

  // Fix fiscal years tabs overflow variables BEGIN

  yearsCurrentIndex: number = 0;
  yearsMaxIndex: number = 0;
  yearsSliceFirstIndex: number = 0;
  yearsSliceLastIndex: number = 0;
  yearsInterval: number = 15;

  // Fix fiscal years tabs overflow variables END

  infoElement: DmsElement;
  iconPath: string;
  viewChecked = false;
  advancedFilters = false;
  advancedSearchResource: Partial<DmsElementResource>;
  currentDirectoryExists = true;

  totalElements$: Observable<number>;
  canCreateFolder$: Observable<boolean>;
  visibleMetadata$: Observable<DmsElementMetadataRegResource[]>;
  searchInTree$: Observable<boolean>;
  searchInTree: boolean;

  dmsDownloadElementResource = new DmsDownloadElementResource();
  fileIdToRemove: number = null;
  currentSphereId: number = null;
  parentSphereId: number = null;
  sphereUserRelation: string = null;
  onlyMetadataComplete = false;

  startedSharing = false;
  startedMoving = false;
  startedConnecting = false;
  startedAttaching = false;

  loading = false;
  openingElementId: number;

  openingZipFile: boolean = false;
  extractingZipFile: boolean = false;

  popoverErrorActive: NgbPopover;
  popoverErrorActiveTimeoutId: any;

  connectionType: ConnectionElementType;
  attachmentConnection: ConnectionElementResource;

  sharingPopupInfo: BehaviorSubject<string> = new BehaviorSubject('');
  sharingPopupInfo$: Observable<string> = this.sharingPopupInfo.asObservable();

  faCircleNotch = faCircleNotch;

  generalCounselDmsSphere: boolean = false;

  searchTerms: any

  isGlobal: boolean = false;

  // csv notification var
  public displayCsvInProgressAlert: boolean = false;

  @Output() enableClearFilters: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('dataTable') dataTable: DataTableComponent<DmsElement>;
  @ViewChild('uploadModal') uploadModal: DocumentsUploaderComponent;
  @ViewChild('newFolderModal') newFolderModal: NewFolderModalComponent;
  @ViewChild('lawConservationModal') lawConservationModal: LawConservationModalComponent;
  @ViewChild('lawConservationSendNowModal') lawConservationSendNowModal: LawConservationSendNowModalComponent;
  @ViewChild('noLawConservationModal') noLawConservationModal: NoLawConservationModalComponent;
  @ViewChild('downloadModal') downloadModal: DownloadModalComponent;
  // @ViewChild('downloadDossierModal') downloadDossierModal: DownloadModalComponent;
  @ViewChild('confirmModal') confirmModal: DocumentConfirmModalComponent;
  @ViewChild('infoModal') infoModal: DocumentMetadataComponent;
  @ViewChild('directoryNotFoundModal') directoryNotFoundModal: ModalComponent;
  @ViewChild('documentDetail') documentDetail: DocumentDetailComponent;
  @ViewChild('shareModal') shareModal: ShareModalComponent;
  @ViewChild('shareShortcutAlert') shareShortcutAlert: ModalComponent;
  @ViewChild('startSharingModal') startSharingModal: StartSharingModalComponent;
  @ViewChild('errorMoving') errorMoving: ModalComponent;
  @ViewChild('favoriteModal') favoriteModal: ModalComponent;
  @ViewChild('addTagModal') addTagModal: AddTagModalComponent;
  @ViewChild('addExportCSVModal') addExportCSVModal: ExportCsvModalComponent;
  @ViewChild('advancedSearchModal') advancedSearchModal: DocumentFilterModalComponent;
  @ViewChild('startConnectionModal') startConnectionModal: StartConnectionModalComponent;
  @ViewChild('connectionModal') connectionModal: ConnectionModalComponent;
  @ViewChild('connectionTypeModal') connectionTypeModal: ConnectionTypeModalComponent;
  @ViewChild('attachmentUploadModal') attachmentUploadModal: AttachmentsUploaderComponent;
  @ViewChild('attachmentModal') attachmentModal: AttachmentModalComponent;
  @ViewChild('startAttachmentModal') startAttachmentModal: StartAttachmentModalComponent;

  @ViewChildren(NgbdSortableHeaderDirective) headers: QueryList<NgbdSortableHeaderDirective>;

  @ViewChildren(CdkColumnDef) customColumns: QueryList<CdkColumnDef>;

  // ---------AUTOCOMPLETE TAG SEARCH--------
  @ViewChild('tagAuto') tagAuto: NgSelectComponent;
  name: string;
  keyword = 'code';
  initialValue = '';
  placeholder = this.translateSrv.instant('documents.component.select-tag');
  tagCodes: string[] = [];
  tagData = [];

  // selectedTag = new Tag();

  sortType = '';
  sortReverse = 0;

  // -------------------------------

  constructor(private router: Router,
    private sphereService: SphereService,
    private dmsMetadataService: DmsMetadataService,
    private dmsFolderRepositoryService: DmsFolderRepositoryService,
    private dmsFileRepositoryService: DmsFileRepositoryService,
    private uploaderService: UploaderService,
    private changeDetectorRef: ChangeDetectorRef,
    private downloaderService: DownloaderService,
    public attachmentCartService: AttachmentCartService,
    private lawConservationService: LawConservationService,
    private spinnerService: SpinnerService,
    private sharingElementsService: SharingElementsService,
    private documentLoaderService: DocumentLoaderService<DmsElement>,
    private toast: ToastrService,
    private generalSettingValueService: GeneralSettingValueService,
    public dmsCartService: DmsCartService,
    public dmsCartMoveService: DmsCartMoveService,
    public connectionCartService: ConnectionCartService,
    public tagService: TagService,
    @Inject(LOCALE_ID) public locale: string,
    private pwcIconsLibrary: PwcIconsLibrary,
    private translateSrv: TranslateService
  ) {
    this.pwcIconsLibrary.registerIcons([
      piAdd,
    ]);
    this.totalElements$ = this.dmsFolderRepositoryService.totalElements$;
    this.canCreateFolder$ = this.dmsFolderRepositoryService.canCreateFolder$;
    this.visibleMetadata$ = this.dmsFolderRepositoryService.visibleMetadata$;
    this.searchInTree$ = this.dmsFolderRepositoryService.searchInTree$;
  }

  getData() {
    let soggetto1Meta = [];
    let sogg1tipoSingletonList = [];
    let valueSoggetto = '';
    this.data.forEach(row => {
      soggetto1Meta = [];
      sogg1tipoSingletonList = [];
      valueSoggetto = '';

      soggetto1Meta.push(...row.metadata.filter(x => x.elementMetadataReg?.code?.startsWith('soggetto_1_')
        && !x.elementMetadataReg?.code?.startsWith('soggetto_1_ruolo')));
      sogg1tipoSingletonList.push(...row.metadata.filter(x => x.elementMetadataReg?.code?.startsWith('soggetto_1_tipo')));

      let sogg1Tipo = sogg1tipoSingletonList.pop();
      sogg1Tipo = sogg1Tipo?.value;

      // show / format soggetto autore's metadata values
      if (row.metadata.filter(x => x.elementMetadataReg?.name.startsWith('Soggetto Autore') && !x.elementMetadataReg?.name.startsWith('Soggetto Autore della modifica')).length === 0) {

        let sogg1SelectedMeta = [];

        switch (sogg1Tipo) {

          case 'PF':
            sogg1SelectedMeta = [
              soggetto1Meta.filter(x => x.elementMetadataReg?.code?.startsWith('soggetto_1_nom_s')).pop()?.value,
              soggetto1Meta.filter(x => x.elementMetadataReg?.code?.startsWith('soggetto_1_cog_s')).pop()?.value,
              soggetto1Meta.filter(x => x.elementMetadataReg?.code?.startsWith('soggetto_1_cf_s')).pop()?.value
            ];
            break;

          case 'PG':
            sogg1SelectedMeta = [
              soggetto1Meta.filter(x => x.elementMetadataReg?.code?.startsWith('soggetto_1_org_s')).pop()?.value,
              soggetto1Meta.filter(x => x.elementMetadataReg?.code?.startsWith('soggetto_1_piva_s')).pop()?.value,
              soggetto1Meta.filter(x => x.elementMetadataReg?.code?.startsWith('soggetto_1_denuff_s')).pop()?.value
            ];
            break;

          case 'PAI':
            sogg1SelectedMeta = [
              soggetto1Meta.filter(x => x.elementMetadataReg?.code?.startsWith('soggetto_1_nomamm_s')).pop()?.value,
              soggetto1Meta.filter(x => x.elementMetadataReg?.code?.startsWith('soggetto_1_ipaaoocode_s')).pop()?.value,
              soggetto1Meta.filter(x => x.elementMetadataReg?.code?.startsWith('soggetto_1_ipauorcode_s')).pop()?.value
            ];
            break;

          case 'PAE':
            sogg1SelectedMeta = [soggetto1Meta.filter(x => x.elementMetadataReg?.code?.startsWith('soggetto_1_nomamm_s')).pop()?.value];
            break;
        }

        valueSoggetto = this.formatSogg1MetaValues(sogg1SelectedMeta);

        if (valueSoggetto !== '' && soggetto1Meta.length != 0) {
          const metadata = new DmsElementMetadata();
          metadata.value = valueSoggetto;
          const metadatareg = new DmsElementMetadataReg();
          metadatareg.id = 0;
          metadatareg.flagValid = true;
          metadatareg.code = 'soggetto_autore';
          metadatareg.name = 'Soggetto Autore';
          metadatareg.required = true;
          metadatareg.searchable = true;
          metadatareg.visibleIndex = true;
          metadatareg.type = MetadataFieldType.STRING;
          metadatareg.aggregatedName = 'Soggetto Autore';
          metadatareg.explicitNotVisible = null;
          metadata.elementMetadataReg = metadatareg;

          metadata.id = 0;
          metadata.error = false;
          metadata.flagValid = true;

          row.metadata = [
            ...row.metadata,
            metadata];
        }
      }
    });
    return this.data;
  }

  /**
   * Graphically format Soggetto 1's metadata values into Soggetto Autore metadata values
   * @param sogg1SelectedMeta is the current metadata values to be formatted. Their codes change according to which soggetto 1's role has been selected
   * @returns the formatted soggetto autore's metadata values
   */
  formatSogg1MetaValues(sogg1SelectedMeta: any[]) {

    let valueSoggetto = '';
    for (let i = 0; i < sogg1SelectedMeta.length; i++) {
      let currentsogg1Meta = sogg1SelectedMeta[i];
      if (currentsogg1Meta != null) {
        if (valueSoggetto != '') {
          valueSoggetto = valueSoggetto + ' - ' + currentsogg1Meta;
        } else if (currentsogg1Meta != ' ') {
          valueSoggetto = currentsogg1Meta;
        }
      }
    }

    return valueSoggetto;
  }

  private setCurrentYear(sphere: Sphere): void {
    this.generalCounselDmsSphere = false;

    if (sphere == null) {
      return;
    }
    this.generalSettingValueService.findByCode('CG_DMS_SPHERE', sphere.societyId)
      .subscribe((list: GeneralSettingValue[]) => {
        if (list == null || list.length === 0) {
          return;
        }

        list.forEach((setting: GeneralSettingValue) => {
          if (setting.value === String(sphere.id)) {
            this.generalCounselDmsSphere = true;
          }
        });
      });
  }

  ngOnInit() {
    this.documentLoaderService.startLoader();

    this.dmsFolderRepositoryService.pagination$.subscribe((pagination: PageableResponse<DmsElementResource[]>) => {
      this.pagination = pagination;
    });

    this.sphereService.currentSphere.pipe(
      tap((sphere: Sphere) => {
        if (!isNullOrUndefined(sphere)) {
          this.getTagList(sphere.id);
        }
      }),
      tap(() => {
        this.data = [];
      }),
      switchMap((sphere: Sphere) => {
        if (isNullOrUndefined(sphere)) {
          // TODO: show error toast
          return of(this.documentLoaderService.rollback());
        }
        if (isNullOrUndefined(this.selectedYear)) {
          return of(this.documentLoaderService.rollback());
        }
        const data: Partial<DmsElementResource> = { sphereId: sphere.id };
        this.dmsDownloadElementResource.sphereId = sphere.id;
        data.fiscalYear = this.selectedYear.year;
        this.searchTerms = data;
        this.documentLoaderService.startLoader();
        return this.dmsFolderRepositoryService.search(data);
      })
    ).subscribe((res: DmsElement[]) => {
      this.resetPathAndUpdateElements(res);
    }, err => {
      this.data = this.documentLoaderService.rollback();
      this.toast.error(this.translateSrv.instant('generic.message.unexpected-error'), this.translateSrv.instant(ToastUtils.TITLE_ERROR));
    });

    this.selectedYear$.pipe(
      tap(() => {
        this.data = [];
        this.documentLoaderService.startLoader();
      }),
      switchMap(() => this.sphereService.currentSphere),
      switchMap((sphere: Sphere) => {
        if (isNullOrUndefined(sphere)) {
          return of(this.data);
        }
        if (isNullOrUndefined(this.selectedYear)) {
          return of(this.data);
        }

        if (this.advancedSearchResource == null) {
          const data: Partial<DmsElementResource> = this.getSearchBody(sphere.id);
          data.fiscalYear = this.selectedYear.year;
          if (!isNullOrUndefined(this.currentDirectory)) {
            data.parentId = this.currentDirectory;
          }
          this.searchTerms = data;
          return this.dmsFolderRepositoryService.search(data);
        } else {
          this.advancedSearchResource.fiscalYear = this.selectedYear.year;
          this.searchTerms = this.advancedSearchResource;
          return this.dmsFolderRepositoryService.search(this.advancedSearchResource);
        }
      })
    ).subscribe((res: DmsElement[]) => {
      this.updateElements(res);
      //  Check if directory exists in current fiscal year.
      this.checkCurrentDirectory();
    });

    this.sphereService.currentSphere.pipe(
      // distinctUntilChanged(),
      switchMap((sphere: Sphere) => {
        if (isNullOrUndefined(sphere)) {
          return of([]);
        }
        this.yearsList = [];
        this.data = [];
        this.selectedYear = null;

        this.setCurrentYear(sphere);

        this.sphereUserRelation = sphere.sphereUserRelation;
        this.parentSphereId = sphere.parentSphereId;
        this.currentSphereId = sphere.id;
        return this.sphereService.getFiscalYears(sphere.id);
      })
    ).subscribe((years: FiscalYearResource[]) => {
      if (!years.length) {
        return;
      }

      this.yearsList = [...years];
      if (this.generalCounselDmsSphere) {
        const currentYear: FiscalYearResource = this.yearsList.find(
          (year) => year.year === (new Date()).getFullYear());
        this.selectYear(currentYear);
      } else {
        this.selectedYear = this.yearsList.reduce(
          (prev: FiscalYearResource, curr: FiscalYearResource) => (curr.year > prev.year) ? curr : prev);
        this.selectedYearSubject.next(this.selectedYear);
      }

      // Fix fiscal years tabs overflow onInit BEGIN

      this.yearsMaxIndex = Math.floor(this.yearsList.length / this.yearsInterval);
      this.yearsCurrentIndex = Math.floor(this.yearsList.indexOf(this.selectedYear) / this.yearsInterval);
      this.yearsSliceFirstIndex = this.yearsCurrentIndex * this.yearsInterval;
      this.yearsSliceLastIndex = this.yearsCurrentIndex * this.yearsInterval + this.yearsInterval;

      // Fix fiscal years tabs overflow onInit END

      return this.selectedYear;
    });

    this.uploaderService.onUploadCompleted.subscribe(() =>{ 
      this.refreshList(); 
    });

    this.uploaderService.onItemCompleted.subscribe(() =>{ 
      this.toast.success(this.translateSrv.instant('upload.completed'), this.translateSrv.instant(ToastUtils.TITLE_SUCCESS));
    });

    this.search$.pipe(
      debounceTime(200),
      tap(() => {
        this.data = [];
        this.documentLoaderService.startLoader();
      }),
      switchMap(() => this.sphereService.currentSphere),
      switchMap((sphere: Sphere) => {
        if (isNullOrUndefined(sphere)) {
          return of(this.data);
        }
        if (isNullOrUndefined(this.selectedYear)) {
          return of(this.data);
        }
        if (this.openingZipFile) {
          return of(this.data);
        }
        this.searchTerms = this.getSearchBody(sphere.id);
        return this.dmsFolderRepositoryService.search(this.getSearchBody(sphere.id));
      }),
    ).subscribe((res: DmsElement[]) => this.updateElements(res));

    this.visibleMetadata$.subscribe(value => {
      this.customMetaColumnsActive = [];
      const aggregatedColumns = new Map<string, string>();
      let showSubject1 = false;
      let showSubject1Count = 0;
      value.forEach(value1 => {
        if (!isNullOrUndefined(value1.aggregatedName)) {
          let temp = aggregatedColumns.get(value1.aggregatedName);
          if (isNullOrUndefined(temp)) {
            temp = '';
          }
          temp += value1.name + ' / ';
          aggregatedColumns.set(value1.aggregatedName, temp);
        } else {
          if (value1.code.includes('soggetto_1_')) {
            showSubject1 = true;
            showSubject1Count++;
          } else {
            this.customMetaColumnsActive.push(value1.name);
          }
        }
      });

      if (showSubject1) {
        this.customMetaColumnsActive.push('Soggetto Autore');
      }


      aggregatedColumns.forEach((value1) => {
        this.customMetaColumnsActive.push(value1.substr(0, value1.length - 3));
      });

    });



    this.searchInTree$.subscribe(value => {
      this.searchInTree = value;
      this.refreshColumns();
    });
  }

  private getSearchBody(idSphere: number): Partial<DmsElementResource> {
    const body: Partial<DmsElementResource> = {
      sphereId: idSphere
    };

    if (this.searchTerm != null && this.searchTerm !== '' && this.searchTerm.length >= 3) {
      body.name = this.searchTerm;
    }

    if (this.tagCodes != null && this.tagCodes.length > 0) {
      body.tagCodes = [];
      body.tagCodes.push(...this.tagCodes);
    }

    body.searchInTheTree = (this.searchTerm != null && this.searchTerm !== ''
      && this.searchTerm.length >= 3) || (this.tagCodes != null && this.tagCodes.length > 0);

    if (this.currentDirectory != null) {
      body.parentId = this.currentDirectory;
    }

    body.fiscalYear = this.selectedYear.year;

    if (this.advancedFilters) {
      body.searchInTheTree = true;

      if (this.advancedSearchResource.tagCodes != null && this.advancedSearchResource.tagCodes.length > 0) {
        body.tagCodes = [];
        body.tagCodes.push(...this.advancedSearchResource.tagCodes);
      }

      if (this.advancedSearchResource.name != null) {
        body.name = this.advancedSearchResource.name;
      }
      if (this.advancedSearchResource.searchStartDate != null) {
        body.searchStartDate = this.advancedSearchResource.searchStartDate;
      }
      if (this.advancedSearchResource.searchEndDate != null) {
        body.searchEndDate = this.advancedSearchResource.searchEndDate;
      }
      if (this.advancedSearchResource.createdDateFrom != null) {
        body.createdDateFrom = this.advancedSearchResource.createdDateFrom;
      }
      if (this.advancedSearchResource.createdDateTo != null) {
        body.createdDateTo = this.advancedSearchResource.createdDateTo;
      }
      body.recent = this.advancedSearchResource.recent;
      if (this.advancedSearchResource.docClassType != null) {
        body.docClassType = this.advancedSearchResource.docClassType;
      }
      if (this.advancedSearchResource.searchMetadata != null) {
        body.searchMetadata = [...this.advancedSearchResource.searchMetadata];
      }
    }

    if (body.searchInTheTree) {
      body.documentType = DocumentTypeEnum.FILE;
    }

    if (!isNullOrUndefined(this.sort)) {
      if (isNullOrUndefined(body.sort)) {
        body.sort = '';
        body.sortType = '';
      }
      this.sort.forEach((value, key) => {
        body.sort = key;
        body.sortType = value;
      });
    } else {
      body.sort = null;
    }

    return body;
  }

  ngAfterViewChecked(): void {
    this.viewChecked = true;
    this.changeDetectorRef.detectChanges();
  }

  get currentDirectory(): number {
    if (this.path.length) {
      return this.path[this.path.length - 1].id;
    }

    return null;
  }

  get isRoot() {
    return isNull(this.currentDirectory);
  }

  get hasFilters() {
    return this.advancedFilters;
  }

  onSearch() {
    if (this.tagCodes.length > 0) {
      this.tagSearch = true;
    } else {
      this.tagSearch = false;
    }
    if (!isNullOrUndefined(this.searchTerm) && (this.searchTerm.length >= 3 || this.searchTerm.length === 0)) {
      this.searchSubject.next(this.searchTerm);
    }
  }

  selectAllRows(select: boolean) {
    this.data.map((item: DmsElement) => {
      if (this.startedSharing && item.selected !== select) {
        if (select) {
          this.dmsCartService.push(item, this.selectedYear);
        } else {
          this.dmsCartService.remove(item);
        }
      }
      if (this.startedMoving && item.selected !== select) {
        if (select) {
          this.dmsCartMoveService.push(item);
        } else {
          this.dmsCartMoveService.remove(item);
        }
      }
      if (this.startedConnecting && item.selected !== select) {
        if (select) {
          this.connectionCartService.push(item, this.selectedYear);
        } else {
          this.connectionCartService.remove(item);
        }
      }
      if (this.startedAttaching && item.selected !== select) {
        if (select) {
          this.attachmentCartService.push(item, this.selectedYear);
        } else {
          this.attachmentCartService.remove(item);
        }
      }
      item.selected = select;

      this.selectedResults = select ? [...this.data] : [];
    })

  }

  selectAllGlobalForExport() {
    this.isGlobal = true;
    this.addExportCSVModal.open();
  }

  globalExportCsv() {
    if (this.advancedFilters || this.tagSearch) {
      this.dmsFolderRepositoryService.exportCsvGlobal(this.searchTerms)
        .subscribe(res => {
          this.displayCsvInProgressAlert = true;
          this.refreshList();
        });
    } else {
      const data = new DmsExportCsv();
      data.sphereId = this.currentSphereId;
      data.fiscalYear = this.selectedYear.year;
      data.ids = [this.data[0].parentId];
      data.conservation = false;
      data.type = ExportCsvType.LIST_BASIC;
        this.dmsFolderRepositoryService.exportCsv(data)
          .subscribe((res: DmsDownloadElement) => {
            this.displayCsvInProgressAlert = true;
            this.refreshList();
          });
    }
  }

  globalExportRichCsv() {
    if (this.advancedFilters || this.tagSearch) {
      this.dmsFolderRepositoryService.exportRichCsvGlobal(this.searchTerms)
        .subscribe(res => {
          this.displayCsvInProgressAlert = true;
          this.refreshList();
        });
    } else {
      const data = new DmsExportCsv();
      data.sphereId = this.currentSphereId;
      data.fiscalYear = this.selectedYear.year;
      data.ids = [this.data[0].parentId];
      data.conservation = false;
      data.type = ExportCsvType.LIST_BASIC;
        this.dmsFolderRepositoryService.exportCsvRich(data)
          .subscribe((res: DmsDownloadElement) => {
            this.displayCsvInProgressAlert = true;
            this.refreshList();
          });
    }

  }

  deselctAllElements() {
    this.selectedResults = [];
    this.data.forEach(elem => {
      elem.selected = false;
    })
  }

  selectRows(id: number) {
    if (this.startedSharing) {
      this.dmsCartService.removeByIdAndYear(id, this.selectedYear);
    }
    if (this.startedMoving) {
      this.dmsCartMoveService.removeById(id);
    }
    if (this.startedConnecting) {
      this.connectionCartService.removeByIdAndYear(id, this.selectedYear);
    }
    if (this.startedAttaching) {
      this.attachmentCartService.removeByIdAndYear(id, this.selectedYear);
    }

    for (const item of this.data) {
      if (item.id === id) {
        item.selected = !item.selected;
        if (this.startedSharing) {
          if (item.selected) {
            this.dmsCartService.push(item, this.selectedYear);
          }
        }
        if (this.startedMoving) {
          if (item.selected) {
            this.dmsCartMoveService.push(item);
          }
        }
        if (this.startedConnecting) {
          if (item.selected) {
            this.connectionCartService.push(item, this.selectedYear);
          }
        }
        if (this.startedAttaching) {
          if (item.selected) {
            this.attachmentCartService.push(item, this.selectedYear);
          }
        }
      }
    }
    this.selectedResults = this.data.filter(e => e.selected);
  }

  selectMetadataIncomplete(): void {
    this.data.map((item: DmsElement) => {
      item.selected = item.documentType === DocumentTypeEnum.FILE && !item.metadataComplete;
    });

    this.selectedResults = this.data.filter((item: DmsElement) => item.selected);
  }

  filterMetadataIncomplete(): void {
    this.sphereService.currentSphere.pipe(
      first(),
      tap(() => {
        this.data = [];
        this.documentLoaderService.startLoader();
      }),
      switchMap((sphere: Sphere) => {
        if (isNullOrUndefined(sphere)) {
          return of(this.data);
        }
        this.searchTerms = {
          sphereId: sphere.id,
          parentId: this.currentDirectory,
          fiscalYear: this.selectedYear.year,
          metadataComplete: false
        };
        return this.dmsFolderRepositoryService.search(this.searchTerms);
      })
    ).subscribe((res: DmsElement[]) => {
      this.updateElements(res, true);
    }, (err: any) => {
      this.toast.error(this.translateSrv.instant('generic.message.unexpected-error'), this.translateSrv.instant(ToastUtils.TITLE_ERROR));
      this.data = this.documentLoaderService.rollback();
    });
  }

  resetMetadataIncompleteFilter(): void {
    this.sphereService.currentSphere.pipe(
      first(),
      tap(() => {
        this.data = [];
        this.documentLoaderService.startLoader();
      }),
      switchMap((sphere: Sphere) => {
        if (isNullOrUndefined(sphere)) {
          return of(this.data);
        }
        this.searchTerms = {
          sphereId: sphere.id,
          parentId: this.currentDirectory,
          fiscalYear: this.selectedYear.year
        };
        return this.dmsFolderRepositoryService.navigate(this.searchTerms);
      })
    ).subscribe((res: DmsElement[]) => {
      this.updateElements(res);
    }, (err: any) => {
      this.toast.error(this.translateSrv.instant('generic.message.unexpected-error'), this.translateSrv.instant(ToastUtils.TITLE_ERROR));
      this.data = this.documentLoaderService.rollback();
    });
  }

  private checkCurrentDirectory() {
    this.currentDirectoryExists = true;
    if (!isNullOrUndefined(this.currentDirectory)) {
      this.sphereService.currentSphere.pipe(
        switchMap((sphere: Sphere) => {
          if (isNullOrUndefined(sphere)) {
            return of(this.data);
          }

          return this.dmsFolderRepositoryService.getInfo({
            id: this.currentDirectory,
            fiscalYear: this.selectedYear.year,
            sphereId: sphere.id
          });
        })
      ).subscribe((dirs: DmsElement[]) => {
        if (dirs.length === 0) {
          this.currentDirectoryExists = false;
          this.directoryNotFoundModal.open();
        }
      });
    }
  }

  openElement(elem: DmsElement) {
    if (this.loading) {
      return;
    }

    if (elem.documentType === DocumentTypeEnum.FOLDER) {
      this.navigateElement(elem);
    } else {
      this.openElementDetail(elem.id, null);
    }
  }

  isLawConservationBadgeModifyHidden(element: DmsElement) {
    return element.isParentAZipFile;
  }

  isLawConservationEnabled(element: DmsElement) {
    return isNullOrUndefined(element.dmsSharing) || element.dmsSharing.permissionLawConservationAllowed;
  }

  labelLawConservationShow(elem: DmsElement): boolean {
    return elem.documentType === DocumentTypeEnum.FOLDER
      && elem.configurationId !== null && elem.configurationId > 0
      // && (isNullOrUndefined(elem.dmsSharing) || elem.dmsSharing.permissionLawConservationAllowed)
      && !elem.isParentAZipFile;
  }

  labelLawConservationParentShow(elem: DmsElement): boolean {
    if (elem.documentType === DocumentTypeEnum.FOLDER) {
      if (elem.configurationId == null && elem.configurationIdParent !== null && elem.configurationIdParent > 0
        && (elem.excludeFromLawConservation == null || elem.excludeFromLawConservation === false)
        && (elem.excludeParentFromLawConservation == null || elem.excludeParentFromLawConservation === false)) {
        return true;
      }
    }
    return false;
  }

  isFavorite(elem: DmsElement): boolean {
    return !isNullOrUndefined(elem.favorite) && elem.favorite;
  }

  getSelectedFileIds(): number[] {
    const ids: number[] = [];
    this.selectedResults.filter(value => value.documentType === DocumentTypeEnum.FILE).forEach(value => ids.push(value.id));
    return ids;
  }

  openElementDetail(fileId: number, isHistoryFileId: number) {
    const ids: number[] = [];
    ids.push(fileId);
    this.documentDetail.open(ids, isHistoryFileId);
  }

  openElementPreview(elementId: number) {
    const ids: number[] = [elementId];
    this.documentDetail.open(ids, null);
  }

  openElementDetails() {
    if (this.selectedResults.length === 1 && this.selectedResults.filter(value => value.documentType === DocumentTypeEnum.FOLDER).length) {
      this.documentDetail.openFolderPreview(this.selectedResults.filter(value => value.documentType === DocumentTypeEnum.FOLDER)[0].id);
    } else {
      this.documentDetail.open(this.getSelectedFileIds(), null);
    }
  }

  openLawConservation() {
    this.lawConservationModal.open();
  }

  openLawConservationSendNow(infoElement: DmsElement) {
    this.infoElement = infoElement;
    this.lawConservationSendNowModal.open();
  }

  openNoLawConservation() {
    this.noLawConservationModal.open();
  }

  openFileZip(elem: DmsElement) {
    this.openingZipFile = true;
    this.navigateElement(elem);

    setTimeout(() => {
      if (this.openingZipFile) {
        this.extractingZipFile = true;
      }
    }, 5000);
  }

  onPageChange(page: number): void {
    this.sphereService.currentSphere.pipe(
      first(),
      tap(() => {
        this.data = [];
        this.documentLoaderService.startLoader();
      }),
      switchMap((sphere: Sphere) => {
        if (sphere == null) {
          return of(this.documentLoaderService.rollback());
        }

        let sortValues = '';
        if (this.sort != null) {
          this.sort.forEach((value, key) => {
            sortValues += key + ',' + value;
          });
        }

        if (sortValues === '') {
          sortValues = 'name';
        }

        const data: Partial<DmsElementResource> = this.getSearchBody(sphere.id);
        data.fiscalYear = this.selectedYear.year;
        data.page = page - 1;
        if (this.onlyMetadataComplete) {
          data.metadataComplete = false;
        }
        this.searchTerms = data;
        return this.dmsFolderRepositoryService.search(data);
      })
    ).subscribe(
      (res: DmsElement[]) => this.updateElements(res, this.onlyMetadataComplete),
      (err: any) => {
        this.toast.error(this.translateSrv.instant('generic.message.unexpected-error'), this.translateSrv.instant(ToastUtils.TITLE_ERROR));
        this.data = this.documentLoaderService.rollback();
      });
  }

  private navigateElement(elem: DmsElement) {
    this.loading = true;
    this.openingElementId = elem.id;
    this.searchTerm = '';

    this.sphereService.currentSphere.pipe(
      first(),
      switchMap((sphere: Sphere) => {
        if (isNullOrUndefined(sphere)) {
          return of(this.data);
        }
        this.searchTerms = { sphereId: sphere.id, parentId: elem.id, fiscalYear: this.selectedYear.year };
        return this.dmsFolderRepositoryService.navigationSearch(this.selectedYear.year,sphere.id,elem.id);
      })
    ).subscribe((res: DmsElement[]) => {
      if (this.path.length > 1) {
        const lastElem = this.path[this.path.length - 1];

        if (lastElem.id === elem.id) {
          console.debug('skip element', elem.id);
          return;
        }
      }

      //  Element is a folder.
      this.path.push(elem);

      this.updateElements(res);
      this.loading = false;
      this.openingElementId = null;
      this.openingZipFile = false;
      this.extractingZipFile = false;
    }, (error: any) => {
      this.loading = false;
      this.openingElementId = null;
      this.openingZipFile = false;
      this.extractingZipFile = false;
    });
  }

  backElement(elem: DmsElement) {
    this.searchTerm = '';
    if (elem == null) {
      this.path = [];
      this.sphereService.currentSphere.pipe(
        tap(() => {
          this.data = [];
          this.documentLoaderService.startLoader();
        }),
        switchMap((sphere: Sphere) => {
          if (isNullOrUndefined(sphere)) {
            return of(this.data);
          }
          return this.dmsFolderRepositoryService.navigate({ sphereId: sphere.id, fiscalYear: this.selectedYear.year });
        })
      ).subscribe(
        (res: DmsElement[]) => this.resetPathAndUpdateElements(res),
        err => {
          this.toast.error(this.translateSrv.instant('generic.message.unexpected-error'), this.translateSrv.instant(ToastUtils.TITLE_ERROR));
          this.data = this.documentLoaderService.rollback();
        }
      );
    } else {
      // Check if it is the last element in path.
      const copy = [...this.path];
      const last = copy.pop();

      if (!isNullOrUndefined(last) && elem.id === last.id) {
        // Do nothing.
        return;
      }

      const p: DmsElement[] = [];
      for (const segment of this.path) {
        p.push(segment);

        if (segment.id === elem.id) {
          break;
        }
      }
      this.path = [...p];
      this.sphereService.currentSphere.pipe(
        switchMap((sphere: Sphere) => {
          if (isNullOrUndefined(sphere)) {
            return of(this.data);
          }
          this.searchTerms = { sphereId: sphere.id, parentId: elem.id, fiscalYear: this.selectedYear.year };
          return this.dmsFolderRepositoryService.navigationSearch(this.selectedYear.year, sphere.id, elem.id);
        })
      ).subscribe((res: DmsElement[]) => {
        this.updateElements(res);

        this.checkCurrentDirectory();
      });
    }
  }

  selectYear(year: FiscalYearResource) {
    this.selectedYear = year;
    this.selectedYearSubject.next(this.selectedYear);
  }

  getElementTypeImageCell(row: DmsElement): string {
    if (row.documentType === DocumentTypeEnum.FOLDER) {
      return '/assets/images/icons/directory.svg';
    } else {
      return getIconByFileExtension(row.extension, row.crossYear);
    }
  }

  getElementTypeAltCell(row: DmsElement): string {
    if (row.documentType === DocumentTypeEnum.FOLDER) {
      return 'Directory';
    } else {
      return 'File';
    }
  }

  onShowInfo($event: DmsElement) {
    this.infoElement = $event;
    this.iconPath = getIconByFileExtension(this.infoElement.extension, this.infoElement.crossYear);
    this.infoModal.open();
  }

  openFileUploader() {
    this.uploadModal.openModal(null, null, null);
  }

  clearFilters() {
    this.advancedFilters = false;
    this.advancedSearchResource = null;
    this.advancedSearchModal.clearFilters();
    this.onSearch();
  }

  openFileUploaderNewVersion(dmsElement: DmsElement) {
    this.documentDetail.forceClose();
    this.uploadModal.openModal(dmsElement.id, false, dmsElement.lawConservation || dmsElement.lawConservationAtLeastOneVersion);
  }

  forceCloseDetail() {
    this.documentDetail.forceClose();

    // this.detailClosed();
  }

  startSharing(): void {
    if (this.hasShortcutInSelection) {
      this.openShareShortcutAlert();
      return;
    }

    if (this.startedSharing) {
      this.openShareModal();
    } else {
      this.dmsCartService.pushAll(this.selectedResults, this.selectedYear);
      this.startedSharing = true;
      this.startSharingModal.open();
    }
  }

  onCancelSharing() {
    this.startSharingModal.close();
    this.dmsCartService.clear();
    this.selectAllRows(false);
    this.startedSharing = false;
  }

  onContinueSharing() {
    // this.startSharingModal.close();
  }

  onConfirmSharing(): void {
    if (this.hasShortcutInSelection) {
      this.openShareShortcutAlert();
      return;
    }

    this.startSharingModal.close();
    this.openShareModal();
  }

  openShareModal(): void {
    this.shareModal.openModal();
  }

  openShareShortcutAlert(): void {
    this.shareShortcutAlert.open();
  }

  startConnecting(): void {
    if (this.startedConnecting) {
      this.startConnectionModal.open();
    } else {
      this.connectionCartService.pushAll(this.selectedResults, this.selectedYear);
      this.startedConnecting = true;
      this.connectionTypeModal.open();
    }
  }

  onConfirmConnection() {
    this.startConnectionModal.close();
    this.openConnectionModal();
  }

  onContinueConnection() {
    // this.startConnectionModal.close();
  }

  openConnectionModal() {
    this.connectionModal.open();
  }

  onCancelConnection() {
    this.connectionType = null;
    this.startConnectionModal.close();
    this.connectionCartService.clear();
    this.selectAllRows(false);
    this.startedConnecting = false;
  }

  onConnectionTypeSelected(type: ConnectionElementType): void {
    this.connectionType = type;
    this.connectionTypeModal.close();
    this.startConnectionModal.open();
  }
  
  onExportCSVTypeSelected(type: ExportCsvType): void {
    const data = new DmsExportCsv();
    data.sphereId = this.currentSphereId;
    data.fiscalYear = this.selectedYear.year;
    data.ids = this.selectedResults.map(r => r.id);
    data.conservation = false;
    if (type === 'LIST_RICH') {
      data.type = ExportCsvType.LIST_BASIC;
      this.dmsFolderRepositoryService.exportCsvRich(data)
        .subscribe((res: DmsDownloadElement) => {
          this.displayCsvInProgressAlert = true;
          this.refreshList();
        });
    } else {
      data.type = type;
      this.dmsFolderRepositoryService.exportCsv(data)
        .subscribe((res: DmsDownloadElement) => {
          this.displayCsvInProgressAlert = true;
          this.refreshList();
        });
    }
  }

  startMoving(): void {
    this.dmsCartMoveService.pushAll(this.selectedResults);
    this.startedMoving = true;
  }

  onConfirmMoving(): void {
    this.dmsCartMoveService.list$.pipe(
      takeWhile(value => value.length > 0),
      map(value => {
        const body = new DmsElementMoveResource();
        body.idDestinationFolder = this.currentDirectory;
        body.fiscalYear = this.selectedYear.year;
        body.fiscalYearId = this.selectedYear.id;
        value.forEach(value1 => body.idElements.push(value1.id));
        return body;
      }),
      switchMap(value => this.dmsFolderRepositoryService.move(value)),
    ).subscribe(value => {
      if (value) {
        this.toast.success(this.translateSrv.instant('generic.message.successful-move'), this.translateSrv.instant(ToastUtils.TITLE_SUCCESS));
        this.onCancelMoving();
        this.refreshList();
      } else {
        this.errorMoving.open();
      }
    }, error => {
      this.errorMoving.open();
    });
  }

  onCancelMoving() {
    this.dmsCartMoveService.clear();
    this.selectAllRows(false);
    this.startedMoving = false;
  }

  private resetPathAndUpdateElements(elements: DmsElement[]) {
    this.path = [];
    this.searchTerm = '';

    this.updateElements(elements);
  }

  private updateElements(elements: DmsElement[], onlyMetadataComplete: boolean = false) {
    this.documentLoaderService.commit([...elements] || []);
    this.data = [...elements] || [];

    this.onlyMetadataComplete = onlyMetadataComplete;
    this.selectedResults = [];
    let selected = [];
    if (this.startedSharing) {
      selected = this.dmsCartService.getSelected(this.selectedYear);
    }
    if (this.startedMoving) {
      selected = this.dmsCartMoveService.getSelected();
    }
    if (this.startedConnecting) {
      selected = this.connectionCartService.getSelected(this.selectedYear);
    }
    if (this.startedAttaching) {
      selected = this.attachmentCartService.getSelected(this.selectedYear);
    }

    if (selected.length > 0) {
      this.data = this.data.map((elem: DmsElement) => {
        if (selected.filter((e: DmsElement) => e.id === elem.id).length > 0) {
          elem.selected = true;
        }
        return elem;
      });
      this.selectedResults = this.data.filter((elem: DmsElement) => elem.selected);
    }

    this.refreshColumns();
  }

  private refreshColumns() {
    let columns = [];
    columns.push('type');
    columns.push('name');
    if (this.searchInTree) {
      columns.push('allVisibleMetadata');
    } else {
      columns = columns.concat(this.customMetaColumnsActive);
      if (this.hasFileInList) {
        columns.push('documentDate');
        columns.push('createdDate');
      }
    }
    columns.push('details');
    columns.push('info');
    this.customColumnsActive = columns;
  }

  openFolderCreator() {
    this.newFolderModal.open();
  }

  openDownloadModal(): void {
    if (this.isSomeFileWithConservation) {
      this.downloadModal.open();
    } else {
      this.downloadModal.downloadStandard.emit();
    }
  }

  openDownloadDossierModal(): void {
    this.dmsDownloadElementResource.conservation = false;
    this.runDownload(true);
    //this.downloadDossierModal.downloadStandard.emit();
  }

  refreshList() {
    this.sphereService.currentSphere.pipe(
      tap(() => {
        this.data = [];
        this.documentLoaderService.startLoader();
      }),
      switchMap((sphere: Sphere) => {
        if (isNullOrUndefined(sphere)) {
          return of(this.data);
        }
        this.searchTerms = this.getSearchBody(sphere.id);
        return this.dmsFolderRepositoryService.search(this.searchTerms);
      }),
    ).subscribe((res: DmsElement[]) => {
      this.updateElements(res);
    }, (err: any) => {
      this.toast.error(this.translateSrv.instant('generic.message.unexpected-error'), this.translateSrv.instant(ToastUtils.TITLE_ERROR));
      this.data = this.documentLoaderService.rollback();
    });
  }

  onHideInfo() {
    this.infoElement = null;
  }

  isFile(infoElement: DmsElement): boolean {
    if (isNullOrUndefined(infoElement)) {
      return false;
    }
    return infoElement.documentType === DocumentTypeEnum.FILE;
  }

  isFileZip(infoElement: DmsElement): boolean {
    if (isNullOrUndefined(infoElement)) {
      return false;
    }
    return infoElement.documentType === DocumentTypeEnum.FILE && !isNullOrUndefined(infoElement.extension)
      && infoElement.extension.indexOf('zip') > -1;
  }

  isFolder(infoElement: DmsElement): boolean {
    if (isNullOrUndefined(infoElement)) {
      return false;
    }
    return infoElement.documentType === DocumentTypeEnum.FOLDER;
  }

  hasIconLawConservation(infoElement: DmsElement): boolean {
    if (infoElement.isParentAZipFile) {
      return false;
    }

    return infoElement.lawConservation
      && !this.hasIconLawConservationNewVersionNotSent(infoElement)
      && !this.hasIconLawConservationRunning(infoElement);
    // && (isNullOrUndefined(infoElement.dmsSharing) || infoElement.dmsSharing.permissionLawConservationAllowed);
  }

  showIconMetadataIncomplete(element: DmsElement): boolean {
    if (element.isParentAZipFile) {
      return false;
    }

    return !this.hasIconNoLawConservation(element);
  }

  hasIconLawConservationToSend(infoElement: DmsElement): boolean {
    if (infoElement.isParentAZipFile) {
      return false;
    }

    return infoElement.lastLawConservationExecution == null &&
      (infoElement.configurationId != null || infoElement.configurationIdParent != null)
      && !this.hasIconLawConservationRunning(infoElement)
      && !this.hasIconNoLawConservation(infoElement)
      && !this.hasIconLawConservationError(infoElement)
      && !this.hasIconLawConservationNoMetadata(infoElement);
    // && (isNullOrUndefined(infoElement.dmsSharing) || infoElement.dmsSharing.permissionLawConservationAllowed);
  }

  hasIconLawConservationRunning(infoElement: DmsElement): boolean {
    if (infoElement.isParentAZipFile) {
      return false;
    }

    return infoElement.lawConservationRunning === true;
    // && (isNullOrUndefined(infoElement.dmsSharing) || infoElement.dmsSharing.permissionLawConservationAllowed);
  }

  hasIconNoLawConservation(infoElement: DmsElement): boolean {
    if (infoElement.isParentAZipFile) {
      return false;
    }

    return (infoElement.excludeFromLawConservation || infoElement.excludeParentFromLawConservation)
      && infoElement.configurationId == null
      && !(infoElement.lawConservation === true);
    // && (isNullOrUndefined(infoElement.dmsSharing) || infoElement.dmsSharing.permissionLawConservationAllowed);
  }

  hasIconLawConservationError(infoElement: DmsElement): boolean {
    if (infoElement.isParentAZipFile) {
      return false;
    }

    return infoElement.lawConservationError && infoElement.lawConservationError !== ''
      && !this.hasIconNoLawConservation(infoElement)
      && !this.hasIconLawConservationNoMetadata(infoElement)
      && !this.hasIconLawConservationRunning(infoElement);
    // && (isNullOrUndefined(infoElement.dmsSharing) || infoElement.dmsSharing.permissionLawConservationAllowed);
  }

  hasIconLawConservationNoMetadata(infoElement: DmsElement): boolean {
    if (infoElement.isParentAZipFile) {
      return false;
    }

    return (infoElement.configurationId != null || infoElement.configurationIdParent != null) && !infoElement.metadataComplete
      && !this.hasIconNoLawConservation(infoElement)
      && !this.hasIconLawConservationRunning(infoElement)
      && !this.hasIconLawConservation(infoElement);
    // && (isNullOrUndefined(infoElement.dmsSharing) || infoElement.dmsSharing.permissionLawConservationAllowed);
  }

  hasIconLawConservationNewVersionNotSent(infoElement: DmsElement): boolean {
    if (infoElement.isParentAZipFile) {
      return false;
    }

    return infoElement.excludeFromLawConservation && infoElement.lastLawConservationExecution != null
      && !this.hasIconLawConservationRunning(infoElement);
    // && (isNullOrUndefined(infoElement.dmsSharing) || infoElement.dmsSharing.permissionLawConservationAllowed);
  }

  errorMessageLawConservation(infoElement: DmsElement): string {
    return infoElement.lawConservationError;
  }

  isErroreConfigurazione(infoElement: DmsElement): boolean {
    return infoElement.lawConservationErrorCode === ErrorCode.CONFIG_ERROR;
  }

  errorPopoverLawConservationEnter(popover: NgbPopover) {
    if (this.popoverErrorActive != null && this.popoverErrorActive.isOpen()) {
      this.popoverErrorActive.close();
    }
    this.popoverErrorActive = popover;
    this.popoverErrorActive.open();
  }

  errorPopoverLawConservationLeave(popover: NgbPopover) {
    const that = this;
    this.popoverErrorActiveTimeoutId = setTimeout(() => {
      that.popoverErrorActive.close();
    }, 100);
  }

  errorPopoverLawConservationHtmlEnter() {
    if (this.popoverErrorActiveTimeoutId != null) {
      clearTimeout(this.popoverErrorActiveTimeoutId);
    }
    this.popoverErrorActiveTimeoutId = null;
  }

  errorPopoverLawConservationHtmlExit() {
    if (this.popoverErrorActive != null && this.popoverErrorActive.isOpen()) {
      this.popoverErrorActive.close();
    }
    this.popoverErrorActive = null;
    this.popoverErrorActiveTimeoutId = null;
  }

  sendLawConservation(infoElement: DmsElement) {
    this.openLawConservationSendNow(infoElement);
  }

  isDownloadLawConservationEnabled(element: DmsElement) {
    return isNullOrUndefined(element.dmsSharing) || element.dmsSharing.permissionLawConservationDownloadAllowed;
  }

  get isSomeFileWithConservation(): boolean {
    return this.selectedResults.filter(value => !value.isParentAZipFile && (value.lawConservation || value.lawConservationAtLeastOneVersion) && this.isDownloadLawConservationEnabled(value)).length > 0 ||
      this.selectedResults.filter(value => !value.isParentAZipFile && value.documentType === DocumentTypeEnum.FOLDER &&
        (value.configurationId != null || value.configurationIdParent != null) &&
        this.isDownloadLawConservationEnabled(value)).length > 0;
  }

  get selectedCount(): number {
    return this.selectedResults.length;
  }

  get isMovingOrSharing(): boolean {
    return this.dmsCartService.isNotEmpty() || this.dmsCartMoveService.isNotEmpty();
  }

  get selectedDimension(): number {
    let dimension = 0;
    this.selectedResults.forEach(value => {
      if (!isNullOrUndefined(value.elementInfo)) {
        value.elementInfo.filter(value1 => value1.fiscalYearId === this.selectedYear.id)
          .forEach(value1 => dimension = dimension + value1.length);
      }
    });
    return dimension;
  }

  get totalCount(): number {
    return this.data.length;
  }

  get anyMetadataIncomplete(): boolean {
    return this.metadataIncompleteCount > 0;
  }

  get showFilesDimensionWarning(): boolean {
    return isBiggerThanOneGigabyte(this.selectedDimension);
  }

  get metadataIncompleteCount(): number {
    return this.data.filter((item: DmsElement) => item.documentType === DocumentTypeEnum.FILE && !item.metadataComplete).length;
  }

  get selectedMetadataIncompleteCount(): number {
    return this.data.filter(
      (item: DmsElement) => item.documentType === DocumentTypeEnum.FILE && !item.metadataComplete && item.selected).length;
  }

  get allMetadataIncompleteSelected(): boolean {
    return this.metadataIncompleteCount === this.selectedMetadataIncompleteCount;
  }

  get hasFileInList(): boolean {
    return this.data.filter((item: DmsElement) => item.documentType === DocumentTypeEnum.FILE).length > 0;
  }

  get hasShortcutInSelection(): boolean {
    return this.selectedResults.reduce((flag: boolean, item: DmsElement) => flag || item.shortcut, false);
  }

  get hasFoldersInSelection(): boolean {
    return this.selectedResults.filter(
      (item: DmsElement) => item.documentType === DocumentTypeEnum.FOLDER && !item.customFolder).length > 0;
  }

  get onlyOneFileSelected(): boolean {
    if (this.selectedResults.filter((item: DmsElement) => item.documentType === DocumentTypeEnum.FOLDER).length > 0) {
      return false;
    } else {
      return this.selectedResults.filter((item: DmsElement) => item.documentType === DocumentTypeEnum.FILE).length === 1;
    }
  }

  get canDownloadDossier(): boolean {
    // only files
    if (this.selectedResults.filter((item: DmsElement) => item.documentType === DocumentTypeEnum.FOLDER).length > 0) {
      return false;
    }
    return this.selectedResults
      .filter((item: DmsElement) => item.documentType === DocumentTypeEnum.FILE).length > 1;
  }

  beingStandardDownload() {
    this.downloadModal.close();
    this.dmsDownloadElementResource.conservation = false;
    this.runDownload(false);
  }

  beginConservationDownload() {
    this.downloadModal.close();
    this.dmsDownloadElementResource.conservation = true;
    this.runDownload(false);
  }

  // beginDossierDownload() {
  //   this.downloadDossierModal.close();
  //   this.dmsDownloadElementResource.conservation = false;
  //   this.runDownload(true);
  // }

  runDownload(isDossier: boolean) {
    this.sphereService.currentSphere.pipe(
      switchMap((sphere: Sphere) => {
        if (isNullOrUndefined(sphere)) {
          return of({});
        }

        this.dmsDownloadElementResource.sphereId = sphere.id;
        this.dmsDownloadElementResource.dossier = isDossier;
        this.dmsDownloadElementResource.fiscalYear = this.selectedYear.year;
        this.dmsDownloadElementResource.ids = [];
        for (const item of this.selectedResults) {
          this.dmsDownloadElementResource.ids.push(item.id);
        }

        return this.dmsFolderRepositoryService.download(this.dmsDownloadElementResource);
      })
    ).subscribe((res: DmsDownloadElement) => {
      this.downloaderService.download(res);
    });
  }

  getMetadataValue(metadata: DmsElementMetadata[], name: string): string {
    if (isNullOrUndefined(DmsElementMetadata)) {
      return '';
    }
    let metaValue = '';
    let aggregated = false;
    for (const metadatum of metadata) {
      if (isNullOrUndefined(metadatum.elementMetadataReg.aggregatedName)) {
        if (metadatum.elementMetadataReg.name === name) {
          metaValue = getMetadataFormattedValue(metadatum, this.locale);
          break;
        }
      } else {
        if (name.indexOf(metadatum.elementMetadataReg.name) > -1) {
          if (isBooleanMetadata(metadatum.elementMetadataReg.type) || !isNullOrUndefined(metadatum.value)) {
            metaValue += getMetadataFormattedValue(metadatum, this.locale) + ' / ';
          }
          aggregated = true;
        }
      }
    }
    if (aggregated && metaValue.length) {
      return metaValue.substr(0, metaValue.length - 3);
    } else {
      return metaValue;
    }
  }

  detailClosed() {
    this.refreshList();
  }

  openDeleteModal(): void {
    this.confirmModal.open();
  }

  confirmDeleteModal(): void {
    const selectIds: number[] = [];
    if (isNullOrUndefined(this.fileIdToRemove)) {
      for (const item of this.selectedResults) {
        selectIds.push(item.id);
      }
    } else {
      selectIds.push(this.fileIdToRemove);
    }
    this.deleting = true;
    this.dmsFolderRepositoryService.deleteByIds(selectIds)
      .subscribe(() => {
        this.confirmModal.close();
        this.documentDetail.forceClose();
        this.fileIdToRemove = null;
        this.deleting = false;

        this.toast.success(this.translateSrv.instant('generic.message.successful-delete'), this.translateSrv.instant(ToastUtils.TITLE_SUCCESS));

        this.refreshList();
      });
  }

  resetPathToRoot() {
    this.path = [];
    this.refreshList();
    this.currentDirectoryExists = true;
  }

  get deleteMessage(): string {
    let message = this.translateSrv.instant('generic.message.delete-question');
    if (this.selectedResults.filter((result: DmsElement) => result.lawConservation || result.lawConservationAtLeastOneVersion).length) {
      message = this.translateSrv.instant('generic.message.delete-not-allowed');
    }

    return message;
  }

  confirmNoLawConservationModal(): void {
    const selectIds: number[] = [];
    for (const item of this.selectedResults) {
      selectIds.push(item.id);
    }

    this.lawConservationService.saveElementNoConservation(this.selectedResults, this.selectedYear.year, this.currentSphereId).subscribe(
      () => {
        this.toast.success(this.translateSrv.instant('generic.message.element-not-law-conserved'), this.translateSrv.instant(ToastUtils.TITLE_SUCCESS));
        this.refreshList();
      });
  }

  get noLawConservationMessage(): string {
    const message = this.translateSrv.instant('generic.message.conserve-selected-element-question');

    return message;
  }

  confirmLawConservationSendNowModal(): void {
    this.infoElement.lawConservationRunning = true;
    this.lawConservationService.sendElementToConservation(this.infoElement, this.selectedYear.year).subscribe(
      () => {
        this.toast.success(this.translateSrv.instant('generic.message.element-sent'), this.translateSrv.instant(ToastUtils.TITLE_SUCCESS));
        this.refreshList();
      });
  }

  get lawConservationSendNowMessage(): string {
    return this.translateSrv.instant('generic.message.conservation-sent-now');
  }

  openDownloadHistoryModal(historyFileId: number): void {
    // TODO Check if is legal conserved
    this.dmsDownloadElementResource.fiscalYear = this.selectedYear.year;
    this.dmsDownloadElementResource.ids = [historyFileId];
    this.dmsDownloadElementResource.sphereId = this.currentSphereId;
    this.dmsFolderRepositoryService.downloadHistoryFile(this.dmsDownloadElementResource).pipe()
      .subscribe((res: DmsDownloadElement) => {
        this.downloaderService.download(res);
      });
  }

  onSort($event: SortEvent) {

    if ($event.direction === SortDirectionEnum.NONE) {
      this.sortReverse = 0;
    } else if ($event.direction === SortDirectionEnum.ASC) {
      this.sortReverse = 1;
    } else if ($event.direction === SortDirectionEnum.DESC) {
      this.sortReverse = 2;
    }

    this.sortType = $event.column;
    if ($event.direction === SortDirectionEnum.NONE) {
      this.sort.delete($event.column);
    } else {
      this.sort.set($event.column, $event.direction);
    }
    this.refreshList();
  }

  openFavorite() {
    this.favoriteModal.open();
  }

  confirmFavoriteModal() {
    this.favoriteModal.close();

    const body = new TagResource();
    body.sphereId = this.currentSphereId;
    body.fiscalYearId = this.selectedYear.id;
    body.idElements = this.selectedResults.map((element: DmsElement) => element.id);

    const el = new TagElementResource();
    el.code = 'favorite';
    el.requiredInsert = true;
    el.onlyForMe = true;
    body.tagElementEntries.push(el);

    this.tagService.addTag(body).subscribe(() => this.onTagAdded());
  }

  formatPath(path: string): string {
    return path.substring(1, path.length).replace(new RegExp('/', 'g'), ' > ').toLowerCase();
  }

  // TAG SEARCH
  getTagList(sphereId?: number) {
    this.name = '';
    this.tagData = [];
    this.initialValue = '';
    const body: Partial<TagResource> = {
      sphereId: sphereId || this.currentSphereId
    };
    this.tagService.search(body).pipe()
      .subscribe((res: Tag[]) => {
        res.forEach(value => {
          this.tagData.push(value);
        });
      });
  }

  onTagAdded() {
    this.toast.success(this.translateSrv.instant('generic.message.tag-addition'), this.translateSrv.instant(ToastUtils.TITLE_SUCCESS));
    this.refreshList();
    this.getTagList();
  }

  openTagModal() {
    this.addTagModal.open();
  }

  openExportCSVModal() {
    this.addExportCSVModal.open();
  }

  openFilterSearch() {
    this.advancedSearchModal.open();
  }

  // ADVANCED SEARCH

  onSearchAdvanced(resource: DmsElementResource) {
    this.advancedFilters = true;
    this.sphereService.currentSphere.pipe(
      tap(() => {
        this.data = [];
        this.documentLoaderService.startLoader();
      }),
      switchMap((sphere: Sphere) => {
        if (isNullOrUndefined(sphere)) {
          return of(this.data);
        }

        resource.sphereId = sphere.id;
        resource.searchInTheTree = true;
        resource.documentType = DocumentTypeEnum.FILE;

        if (!isNullOrUndefined(this.currentDirectory)) {
          resource.parentId = this.currentDirectory;
        }
        if (!isNullOrUndefined(this.selectedYear)) {
          resource.fiscalYear = this.selectedYear.year;
        }

        if (!isNullOrUndefined(this.sort)) {
          if (isNullOrUndefined(resource.sort)) {
            resource.sort = '';
            resource.sortType = '';
          }
          this.sort.forEach((value, key) => {
            resource.sort = key;
            resource.sortType = value;
          });
        } else {
          resource.sort = null;
        }

        this.advancedSearchResource = resource;
        this.searchTerms = resource;
        return this.dmsFolderRepositoryService.search(resource);
      }),
    ).subscribe(
      (res: DmsElement[]) => this.updateElements(res),
      (err: any) => {
        this.toast.error(this.translateSrv.instant('generic.message.unexpected-error'), this.translateSrv.instant(ToastUtils.TITLE_ERROR));
        this.data = this.documentLoaderService.rollback();
      }
    );
  }

  getAllVisibleMetadata(dmsElement: DmsElement): string {
    let metadata = '';
    if (!isNullOrUndefined(dmsElement.metadata)) {
      dmsElement.metadata.forEach(value => {
        if (value.elementMetadataReg.visibleIndex) {
          metadata += value.elementMetadataReg.name + ': ' + getMetadataFormattedValue(value, this.locale) + ' | ';
        }
      });
    }

    if (metadata.length > 0) {
      metadata = metadata.substring(0, metadata.length - 2);
    }

    return metadata;
  }

  onShareConfirmed(): void {
    this.toast.success(this.translateSrv.instant('generic.message.selected-element-shared'), this.translateSrv.instant(ToastUtils.TITLE_SUCCESS));

    this.refreshList();
    // this.dmsCartService.clear();
    this.startedSharing = false;
  }

  onConnectionConfirmed(): void {
    this.toast.success(this.translateSrv.instant('generic.message.selected-element-connected'), this.translateSrv.instant(ToastUtils.TITLE_SUCCESS));

    this.refreshList();
    this.startedConnecting = false;
  }

  startAttaching(): void {
    if (this.startedAttaching) {
      this.startAttachmentModal.open();
    } else {
      this.attachmentCartService.pushAll(this.selectedResults, this.selectedYear);
      this.startedAttaching = true;
      this.startAttachmentModal.open();
    }
  }

  openAttachmentsUploader(): void {
    // Remove items from previous uploads.
    this.startAttachmentModal.close();
    this.uploaderService.removeAll();
    this.attachmentUploadModal.openModal();
  }

  onUploadAttachmentsConfirmed(): void {
    const connection = new ConnectionElementResource();
    connection.type = ConnectionElementType.GROUP;
    connection.sphereId = this.currentSphereId;
    connection.fiscalYear = this.selectedYear.year;
    connection.fiscalYearId = this.selectedYear.id;
    connection.elements = [];
    for (const item of this.attachmentCartService.getItems()) {
      const entry = new ConnectionElementEntryResource();
      entry.idElement = item.elementId;
      entry.fiscalYear = item.fiscalYear;
      entry.fiscalYearId = item.fiscalYearId;
      connection.elements.push(entry);
    }

    this.attachmentConnection = connection;

    this.attachmentModal.open();
  }

  onAttachmentCanceled() {
    this.attachmentCartService.clear();
    this.uploaderService.removeAll();
    this.startedAttaching = false;
    this.refreshList();
  }

  onAttachmentConfirmed() {
    this.toast.success(this.translateSrv.instant('generic.message.selected-element-uploaded'), this.translateSrv.instant(ToastUtils.TITLE_SUCCESS));

    this.attachmentCartService.clear();
    this.startedAttaching = false;
    this.refreshList();
    this.attachmentModal.close();
  }

  isLoading(elem: DmsElement): boolean {
    return this.loading && this.openingElementId === elem.id;
  }

  getSharingPopupDescription(element: DmsElement): void {
    this.sharingPopupInfo.next('');
    this.sphereService.currentSphere.pipe(
      switchMap((sphere: Sphere) => {
        const search: Partial<DmsSharingResource> = {
          sphereId: sphere.id,
          elementId: element.id,
          fiscalYearId: this.selectedYear.id
        };
        return this.sharingElementsService.searchByElement(search);
      })
    ).subscribe((list: DmsSharing[]) => {
      let info = '';
      for (const sharing of list) {
        info += '<p>' + sharing.name + ': ';
        sharing.share2Users.forEach(value2 => {
          info += value2.username + ', ';
        });
        info.substr(0, info.length - 2);
        info += '</p>';
      }

      this.sharingPopupInfo.next(info);
    });
  }

  // Fix fiscal years tabs overflow BEGIN

  onPrevFiscalYearsClick() {
    this.yearsCurrentIndex--;
  }

  onNextFiscalYearsClick() {
    this.yearsCurrentIndex++;
  }

  checkCurrentIndex() {
    this.yearsSliceFirstIndex = this.yearsCurrentIndex * this.yearsInterval;
    this.yearsSliceLastIndex = this.yearsCurrentIndex * this.yearsInterval + this.yearsInterval;

    if (this.yearsCurrentIndex == 0) {
      return -1;
    }
    else if (this.yearsCurrentIndex == this.yearsMaxIndex) {
      return 1;
    }
    return 0;
  }

  // Fix fiscal years tabs overflow END
}