<list-toolbar *ngIf="selected">
  <ng-container toolbar-actions>
    <button placement="top" [ngbTooltip]="'generic.action.download'|translate" type="button" *ngIf="canDownload"
      class="btn btn-primary btn-sm btn-action-icon trigger-hover" (click)="onDownload()"
      [disabled]="checkButtonEnableByDimension()">
      <pwc-icon name="download"></pwc-icon>
    </button>
    <button placement="top" [ngbTooltip]="'generic.action.download-dossier'|translate" type="button"
      class="btn btn-primary btn-sm btn-action-icon trigger-hover" [disabled]="checkButtonEnableByDimension()"
      (click)="onDownloadDossier()" *ngIf="canDownloadDossier">
      <img src="/assets/images/icons/documents/toolbar/download_dossier.svg"
        [alt]="'generic.action.download-dossier'|translate" class="hidden-hover">
      <img src="/assets/images/icons/documents/toolbar/download_dossier_hover.svg"
        [alt]="'generic.action.download-dossier'|translate" class="visible-hover">
    </button>
    <button placement="top" [ngbTooltip]="'generic.action.generate-sharing'|translate" type="button" *ngIf="canShare"
      class="btn btn-primary btn-sm btn-action-icon trigger-hover" (click)="onShare()">
      <pwc-icon name="share"></pwc-icon>
    </button>
    <button placement="top" [ngbTooltip]="'generic.action.show-item'|translate" type="button"
      class="btn btn-primary btn-sm btn-action-icon trigger-hover" (click)="onView()" *ngIf="!isHybridSelection && !removeEye()">
      <pwc-icon name="view"></pwc-icon>
    </button>
    <button placement="top" [ngbTooltip]="'generic.action.move'|translate" type="button"
      class="btn btn-primary btn-sm btn-action-icon trigger-hover" (click)="onMove()" *ngIf="!hasFolders && canMove">
      <img src="/assets/images/icons/documents/toolbar/move.svg" [alt]="'generic.action.move'|translate"
        class="hidden-hover">
      <img src="/assets/images/icons/documents/toolbar/move-hover.svg" [alt]="'generic.action.move'|translate"
        class="visible-hover">
    </button>
    <button placement="top" [ngbTooltip]="'connection.modal.link'|translate" type="button"
      class="btn btn-primary btn-sm btn-action-icon trigger-hover" (click)="onConnect()"
      *ngIf="canConnect && !isLawConserved()">
      <img src="/assets/images/icons/documents/toolbar/link.svg" [alt]="'connection.modal.link'|translate"
        class="hidden-hover">
      <img src="/assets/images/icons/documents/toolbar/link-hover.svg" [alt]="'connection.modal.link'|translate"
        class="visible-hover">
    </button>
    <button placement="top" [ngbTooltip]="'generic.action.attach'|translate" type="button"
      class="btn btn-primary btn-sm btn-action-icon trigger-hover" (click)="onAttach()"
      *ngIf="canAttach && !isLawConserved()">
      <img src="/assets/images/icons/documents/toolbar/paper-clip.svg" [alt]="'generic.action.attach'|translate"
        class="hidden-hover">
      <img src="/assets/images/icons/documents/toolbar/paper-clip-hover.svg" [alt]="'generic.action.attach'|translate"
        class="visible-hover">
    </button>
    <button placement="top" [ngbTooltip]="'tag.add'|translate" type="button"
      class="btn btn-primary btn-sm btn-action-icon trigger-hover" (click)="onTag()" *ngIf="canTag">
      <img src="/assets/images/icons/documents/toolbar/tag.svg" [alt]="'tag.add'|translate" class="hidden-hover">
      <img src="/assets/images/icons/documents/toolbar/tag-hover.svg" [alt]="'tag.add'|translate" class="visible-hover">
    </button>
    <button placement="top" [ngbTooltip]="'generic.action.add-favourite'|translate" type="button"
      class="btn btn-primary btn-sm btn-action-icon trigger-hover" (click)="onFavorite()"
      *ngIf="getFavoritePermission()">
      <img src="/assets/images/icons/documents/toolbar/star.svg" [alt]="'generic.action.add-favourite'|translate"
        class="hidden-hover">
      <img src="/assets/images/icons/documents/toolbar/star-hover.svg" [alt]="'generic.action.add-favourite'|translate"
        class="visible-hover">
    </button>
    <button placement="top" [ngbTooltip]="'generic.action.new-version'|translate" type="button"
      class="btn btn-primary btn-sm btn-action-icon trigger-hover" (click)="onNewFileVersion()"
      *ngIf="onlyOneFileSelected && canUploadFile && !isLawConserved()">
      <img src="/assets/images/icons/documents/toolbar/new_document.svg" [alt]="'generic.action.new-version'|translate"
        class="hidden-hover">
      <img src="/assets/images/icons/documents/toolbar/new_document-hover.svg"
        [alt]="'generic.action.new-version'|translate" class="visible-hover">
    </button>
    <button placement="top" [ngbTooltip]="'generic.action.export-csv'|translate" type="button"
      class="btn btn-primary btn-sm btn-action-icon trigger-hover" (click)="onExportCSV()" *ngIf="canExportCSV">
      <pwc-icon name="export"></pwc-icon>
    </button>
    <!-- TODO: New document - not needed here in IMHO -->
    <!--    <button placement="top" ngbTooltip="Esporta" type="button" class="btn btn-primary btn-sm btn-action-icon trigger-hover"-->
    <!--            (click)="onExport()" *ngIf="!hasFolders">-->
    <!--      <img src="/assets/images/icons/documents/toolbar/csv.svg" alt="Esporta" class="hidden-hover">-->
    <!--      <img src="/assets/images/icons/documents/toolbar/csv-hover.svg" alt="Esporta" class="visible-hover">-->
    <!--    </button>-->
    <button placement="top" [ngbTooltip]="'generic.action.conserve-to-law'|translate" type="button"
      class="btn btn-primary btn-sm btn-action-icon trigger-hover"
      *ngIf="showConservation && !isParentAZipFile() && getLawConservationPermission()" (click)="onLawConservation()">
      <img src="/assets/images/icons/documents/toolbar/conservazione_a_norma.svg"
        [alt]="'generic.action.conserve-to-law'|translate" class="hidden-hover">
      <img src="/assets/images/icons/documents/toolbar/conservazione_a_norma-hover.svg"
        [alt]="'generic.action.conserve-to-law'|translate" class="visible-hover">
    </button>
    <button placement="top" [ngbTooltip]="'generic.action.no-conserve-to-law'|translate" type="button"
      class="btn btn-primary btn-sm btn-action-icon trigger-hover"
      *ngIf="showConservation && !isParentAZipFile() && getLawConservationPermission() && !hideNoLawConservation()"
      (click)="onNoLawConservation()">
      <img src="/assets/images/icons/documents/toolbar/no_conservazione.svg"
        [alt]="'generic.action.no-conserve-to-law'|translate" class="hidden-hover">
      <img src="/assets/images/icons/documents/toolbar/no_conservazione-hover.svg"
        [alt]="'generic.action.no-conserve-to-law'|translate" class="visible-hover">
    </button>
    <button placement="top" [ngbTooltip]="'generic.action.delete.label'|translate" type="button"
      class="btn btn-primary btn-sm btn-action-icon trigger-hover" (click)="onDelete()"
      *ngIf="!hasFolders && canDelete && !isLawConserved()">
      <pwc-icon name="delete"></pwc-icon>
    </button>
  </ng-container>

  <ng-container toolbar-text>
    <span *ngIf="selected>1"> {{'documents.toolbar.have-been-selected'|translate}} {{ selected }}
      <span *ngIf="selected < totalElements">{{'documents.toolbar.element-in-page'|translate}}</span>
      <span *ngIf="selected >= totalElements">{{'documents.toolbar.elements'|translate}}</span></span>
    <span *ngIf="selected==1"> {{'documents.toolbar.have-been-selected-single'|translate}} {{ selected }}
      {{'documents.toolbar.element-in-page-single'|translate}}</span> &nbsp;
    <a [routerLink]="" *ngIf="selected == 50" class="{{loadingAllGlobal ? 'disabled' : ''}}" disabled="{{loadingAllGlobal}}"
      (click)="!loadingAllGlobal && selectAllGlobalForExport()">{{'documents.toolbar.select-all-elements-for-export'|translate}} {{totalElements}}
      {{'documents.toolbar.elements'|translate}}</a>
    <a [routerLink]="" *ngIf="selected >= totalElements"
      (click)="deselectAll()">{{'documents.toolbar.deselect-all-elements'|translate}} </a>
    <!--<a [routerLink]="" *ngIf="selected != total" (click)="selectAllRows()">
      {{'documents.toolbar.select-all'|translate}}
    </a>-->
  </ng-container>
</list-toolbar>