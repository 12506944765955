import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { PageableResponse } from '../../../../models/api/pageable-response';
import { ColumnDef } from '../../../../models/common/data-table/column-def';
import { SortDirectionEnum } from '../../../../enums/common/sort-direction.enum';
import { Router } from '@angular/router';
import { CdkColumnDef } from '@angular/cdk/table';
import { NgbdSortableHeaderDirective, SortEvent } from '../../../../directives/common/sortable.directive';
import { DataTableComponent } from '../../../common/data-table/data-table.component';
import { debounceTime, switchMap } from 'rxjs/operators';
import { Sphere } from '../../../../models/sphere';
import { SphereService } from '../../../../services/sphere.service';
import { LawConservationListConfigurationService } from '../../../../services/law-conservation/law-conservation-list-configuration.service';
import { LawConservationConfiguration } from '../../../../models/law-conservation/law-conservation-configuration';
import { LawConservationListConfigurationResource } from '../../../../models/api/law-conservation/law-conservation-list-configuration-resource';
import { ServiceTypeEnum } from '../../../../enums/law-conservation/service-type.enum';
import { LawConservationListBucketResource } from '../../../../models/api/law-conservation/law-conservation-list-bucket-resource';
import { ModalComponent } from '../../../common/modals/modal/modal.component';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { validateDate } from '../../../../helpers/common/date-utils';
import { SendingFrequencyTypeEnum } from '../../../../enums/law-conservation/sending-frequency-type.enum';
import { LawConservationFrequency } from '../../../../models/law-conservation/law-conservation-frequency';
import { FiscalYearResource } from '../../../../models/api/fiscal-year-resource';
import { LawConservationListBucketService } from '../../../../services/law-conservation/law-conservation-list-bucket.service';
import { LawConservationBucket } from '../../../../models/law-conservation/law-conservation-bucket';
import { LawConservationConfigurationFiscalYear } from '../../../../models/law-conservation/law-conservation-configuration-fiscal-year';
import { FiscalYear } from '../../../../models/fiscalYear';
import { LocalDate } from '../../../../models/local-date.model';
import { piCalendarSmall, piClear, piDelete, piEdit, piView, PwcIconsLibrary, piAdd } from "@pwc/icons";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-law-conservation-configuration',
  templateUrl: './law-conservation-configuration.component.html',
  styleUrls: ['./law-conservation-configuration.component.scss'],
  providers: [LawConservationListConfigurationService]
})
export class LawConservationConfigurationComponent implements OnInit, AfterViewChecked {

  data: LawConservationConfiguration[] = [];
  pagination: PageableResponse<LawConservationListConfigurationResource[]>;
  columns: ColumnDef<LawConservationConfiguration>[] = [
    {
      field: 'name',
      header: this.translateSrv.instant('generic.field.name'),
      sortable: true,
      cell: (row: LawConservationConfiguration) => `${(row.name ? row.name : '')}`,
    }
  ];
  customColumnsActive: string[] = [];
  customMetaColumnsActive: string[] = [];

  sort: Map<string, SortDirectionEnum> = new Map();
  viewChecked = false;

  searchTerm = '';
  searchTermYear: number;
  searchTermAlias: string;

  searchSubject: Subject<string> = new Subject<string>();
  search$: Observable<string> = this.searchSubject.asObservable();

  selectedConfiguration: LawConservationConfiguration = new LawConservationConfiguration();
  newConfiguration: LawConservationConfiguration = new LawConservationConfiguration();
  loadedFiscalYear: LawConservationConfigurationFiscalYear[];
  selectedFiscalYear: number[];

  currentSphereId: number;
  currentUserId: number;
  yearsList: FiscalYearResource[];
  bucketList: LawConservationBucket[];

  preview: boolean;

  startDate: LocalDate;

  errorName: string;
  errorBucket: string;
  errorFrequency: string;
  errorYears: string;
  errorSendingDate: string;
  errorSignMethod: string;
  errorSignAlias: string;
  errorSignPassword: string;

  frequencyList: LawConservationFrequency[];

  @ViewChild('dataTable') dataTable: DataTableComponent<LawConservationConfiguration>;
  @ViewChildren(NgbdSortableHeaderDirective) headers: QueryList<NgbdSortableHeaderDirective>;
  @ViewChildren(CdkColumnDef) customColumns: QueryList<CdkColumnDef>;
  @ViewChild('deleteConfigurationModal') deleteConfigurationModal: ModalComponent;
  @ViewChild('editConfigurationModal') editConfigurationModal: ModalComponent;
  @ViewChild('createConfiguration') createConfiguration: ModalComponent;

  constructor(private router: Router, private changeDetectorRef: ChangeDetectorRef, private sphereService: SphereService,
    private lawConservationConfigService: LawConservationListConfigurationService, private bucketService: LawConservationListBucketService,
    private iconsLibrary: PwcIconsLibrary, private translateSrv: TranslateService) {
    iconsLibrary.registerIcons([
      piClear,
      piView,
      piEdit,
      piDelete,
      piCalendarSmall,
      piAdd
    ]);
  }

  ngOnInit(): void {
    this.lawConservationConfigService.pagination$.subscribe((pagination: PageableResponse<LawConservationListConfigurationResource[]>) => {
      this.pagination = pagination;
    });

    this.refreshList();

    this.loadYearList();

    this.search$.pipe(
      debounceTime(200),
      switchMap(() => this.sphereService.currentSphere),
      switchMap((sphere: Sphere) => {
        if (isNullOrUndefined(sphere)) {
          return of(this.data);
        }
        this.currentSphereId = sphere.id;
        this.currentUserId = sphere.userId;
        const body: Partial<LawConservationListConfigurationResource> = {
          sphereId: sphere.id,
          userId: sphere.userId
        };
        if (!isNullOrUndefined(this.searchTerm) && this.searchTerm !== '' && this.searchTerm.length >= 3) {
          body.name = this.searchTerm;
        }
        if (!isNullOrUndefined(this.searchTermYear) && this.searchTermYear > 0) {
          body.searchFiscalYear = this.searchTermYear;
        }
        if (!isNullOrUndefined(this.searchTermAlias) && this.searchTermAlias !== '' && this.searchTermAlias.length >= 3) {
          body.alias = this.searchTermAlias;
        }

        if (!isNullOrUndefined(this.sort)) {
          if (isNullOrUndefined(body.sort)) {
            body.sort = '';
          }
          this.sort.forEach((value, key) => {
            body.sort += key + ',' + value;
          });
        } else {
          body.sort = null;
        }

        return this.lawConservationConfigService.search(body);
      }),
    ).subscribe((res: LawConservationConfiguration[]) => this.updateElements(res));
  }

  private refreshList() {
    this.sphereService.currentSphere.pipe(
      switchMap((sphere) => {
        if (isNullOrUndefined(sphere)) {
          return of(this.data);
        }
        this.currentSphereId = sphere.id;

        const data: Partial<LawConservationListConfigurationResource> = { sphereId: sphere.id, userId: sphere.userId };

        if (!isNullOrUndefined(this.sort)) {
          if (isNullOrUndefined(data.sort)) {
            data.sort = '';
          }
          this.sort.forEach((value, key) => {
            data.sort += key + ',' + value;
          });
        } else {
          data.sort = null;
        }
        return this.lawConservationConfigService.search(data);
      })
    ).subscribe((res: LawConservationConfiguration[]) => this.resetPathAndUpdateElements(res));
  }

  private resetPathAndUpdateElements(elements: LawConservationConfiguration[]) {
    this.searchTerm = '';
    this.updateElements(elements);
  }

  private updateElements(elements: LawConservationConfiguration[]) {
    this.data = [...elements] || [];
    this.refreshColumns();
  }

  private refreshColumns() {
    let columns = [];
    columns = columns.concat(this.customMetaColumnsActive);
    columns.push('bucket');
    columns.push('years');
    columns.push('frequency');
    columns.push('alias');
    columns.push('creation_date');
    columns.push('last_execution');
    columns.push('next_execution');
    columns.push('status');
    columns.push('details');
    this.customColumnsActive = columns;
  }

  ngAfterViewChecked(): void {
    this.viewChecked = true;
    this.changeDetectorRef.detectChanges();
  }

  emptyFilters() {
    this.searchTerm = '';
    this.searchTermYear = null;
    this.searchTermAlias = '';
    this.onSearch();
  }

  onSearch() {
    if (!isNullOrUndefined(this.searchTerm) && this.searchTerm.length >= 3) {
      this.searchSubject.next(this.searchTerm);
    } else {
      this.searchSubject.next(null);
    }
  }

  onPageChange(page: number): void {

    let sortValues = '';
    if (!isNullOrUndefined(this.sort)) {
      this.sort.forEach((value, key) => {
        sortValues += key + ',' + value;
      });
    } else {
      sortValues = null;
    }

    const data: Partial<LawConservationListConfigurationResource> = {
      sort: sortValues,
      page: page - 1,
      sphereId: this.currentSphereId,
      userId: this.currentUserId
    };

    this.lawConservationConfigService.search(data).pipe()
      .subscribe((res: LawConservationConfiguration[]) => this.updateElements(res));
  }

  onSort($event: SortEvent) {
    if ($event.direction === SortDirectionEnum.NONE) {
      this.sort.delete($event.column);
    } else {
      this.sort.set($event.column, $event.direction);
    }
    this.refreshList();
  }

  getYears(row: LawConservationConfiguration): string {
    const years = [];
    for (const fiscalYear of row.fiscalYear) {
      years.push(fiscalYear.fiscalYear.year);
    }

    return years.join(' - ');
  }

  enableChanges(element: LawConservationConfiguration): boolean {
    return true;
  }

  enableDelete(element: LawConservationConfiguration): boolean {
    return element.allowDelete;
  }

  isDisabled(): boolean {
    return !this.selectedConfiguration.allowModify;
  }

  openDeleteElement(element: LawConservationConfiguration) {
    this.copyElement(element);
    this.deleteConfigurationModal.open();
  }

  loadYearList() {
    this.sphereService.currentSphere.pipe(
      // distinctUntilChanged(),
      switchMap((sphere: Sphere) => {
        if (isNullOrUndefined(sphere)) {
          return of([]);
        }
        this.currentSphereId = sphere.id;

        return this.sphereService.getFiscalYears(sphere.id);
      })
    ).subscribe((years: FiscalYearResource[]) => {
      if (!years.length) {
        return;
      }

      this.yearsList = [...years];
      return;
    });
  }

  private beforeOpenModal() {
    this.clearErrors();
    this.initFrequency();
    this.selectedFiscalYear = [];
    this.loadedFiscalYear = [];
    this.startDate = null;

    this.sphereService.currentSphere.pipe(
      // distinctUntilChanged(),
      switchMap((sphere: Sphere) => {
        if (isNullOrUndefined(sphere)) {
          return of([]);
        }
        this.currentSphereId = sphere.id;
        this.sort = new Map();

        const lawConservationListBucketResource = new LawConservationListBucketResource();
        lawConservationListBucketResource.sphereId = sphere.id;
        lawConservationListBucketResource.userId = sphere.userId;

        this.bucketService.search(lawConservationListBucketResource).subscribe((res: LawConservationBucket[]) => {
          this.bucketList = res;
        });

        return this.sphereService.getFiscalYears(sphere.id);
      })
    ).subscribe((years: FiscalYearResource[]) => {
      if (!years.length) {
        return;
      }

      this.yearsList = [...years];
      return;
    });
  }

  isPreview() {
    return this.preview;
  }

  openEditElement(element: LawConservationConfiguration, preview: boolean) {
    this.preview = preview;
    this.beforeOpenModal();
    this.copyElement(element);
    this.loadedFiscalYear = element.fiscalYear;

    for (const fiscalYear of element.fiscalYear) {
      this.selectedFiscalYear.push(fiscalYear.fiscalYear.id);
    }

    if (element.startSending != null) {
      const date = new Date(element.startSending);
      this.startDate = new LocalDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
    }

    this.editConfigurationModal.open();
  }

  openCrateElement() {
    this.beforeOpenModal();
    this.newConfiguration = new LawConservationConfiguration();
    this.newConfiguration.preservationSystem = ServiceTypeEnum.INFOCERT;
    this.createConfiguration.open();
  }

  removeElement() {
    this.deleteConfigurationModal.close();
    this.lawConservationConfigService.delete(this.selectedConfiguration.id).pipe(switchMap(value => {
      const body: Partial<LawConservationListConfigurationResource> = {
        sphereId: this.currentSphereId,
        userId: this.currentUserId
      };
      return this.lawConservationConfigService.search(body);
    })).subscribe((res: LawConservationConfiguration[]) => {
      this.selectedConfiguration = new LawConservationConfiguration();
      this.updateElements(res);
    });
  }

  clearErrors() {
    this.errorName = '';
    this.errorBucket = '';
    this.errorFrequency = '';
    this.errorYears = '';
    this.errorSendingDate = '';
    this.errorSignMethod = '';
    this.errorSignAlias = '';
    this.errorSignPassword = '';
  }

  beforeSave(element: LawConservationConfiguration): boolean {
    this.clearErrors();

    element.fiscalYear = [];
    for (const fiscalYear of this.selectedFiscalYear) {
      const loadedFiscalYear = new LawConservationConfigurationFiscalYear();
      const fy = new FiscalYear();

      fy.id = fiscalYear;
      loadedFiscalYear.fiscalYear = fy;
      for (const lawConservationFiscalYear of this.loadedFiscalYear) {
        if (lawConservationFiscalYear.fiscalYear.id === fiscalYear) {
          loadedFiscalYear.id = lawConservationFiscalYear.id;
        }
      }
      element.fiscalYear.push(loadedFiscalYear);
    }

    if (element.name == null || element.name.trim() === '') {
      this.errorName = this.translateSrv.instant('documents.law.configuration.name-required');
    }
    if (element.bucketId == null) {
      this.errorBucket = this.translateSrv.instant('documents.law.configuration.bucket-required');
    }
    if (element.sendingFrequency == null) {
      this.errorFrequency = this.translateSrv.instant('documents.law.configuration.frequency-required');
    }
    if (element.startSending == null) {
      this.errorSendingDate = this.translateSrv.instant('documents.law.configuration.start-date-required');
    }
    if (element.preservationSystem == null) {
      this.errorSignMethod = this.translateSrv.instant('documents.law.configuration.signature-required');
    }
    if (element.alias == null || element.alias.trim() === '') {
      this.errorSignAlias = this.translateSrv.instant('documents.law.configuration.alias-signature-required');
    }
    if (element.pin == null || element.pin.trim() === '') {
      this.errorSignPassword = this.translateSrv.instant('documents.law.configuration.pin-signature-required');
    }

    if ((this.errorName + this.errorBucket + this.errorFrequency + this.errorSendingDate +
      this.errorSignMethod + this.errorSignAlias + this.errorSignPassword) === '') {
      return true;
    }
    return false;
  }

  editElement() {
    if (this.beforeSave(this.selectedConfiguration)) {
      this.editConfigurationModal.close();
      const elementResource = this.lawConservationConfigService.convertModelToResource(this.selectedConfiguration);
      elementResource.sphereId = this.currentSphereId;
      elementResource.userId = this.currentUserId;
      elementResource.flagValid = true;

      this.lawConservationConfigService.update(elementResource).pipe(switchMap(value => {
        const body: Partial<LawConservationListConfigurationResource> = {
          sphereId: this.currentSphereId,
          userId: this.currentUserId
        };
        return this.lawConservationConfigService.search(body);
      })).subscribe((res: LawConservationConfiguration[]) => {
        this.selectedConfiguration = new LawConservationConfiguration();
        this.updateElements(res);
      });
    }
  }

  addElement() {
    if (this.beforeSave(this.newConfiguration)) {
      this.createConfiguration.close();
      const elementResource = this.lawConservationConfigService.convertModelToResource(this.newConfiguration);

      elementResource.sphereId = this.currentSphereId;
      elementResource.userId = this.currentUserId;
      elementResource.flagValid = true;

      this.lawConservationConfigService.insert(elementResource).pipe(switchMap(value => {
        const body: Partial<LawConservationListConfigurationResource> = {
          sphereId: this.currentSphereId,
          userId: this.currentUserId
        };
        return this.lawConservationConfigService.search(body);
      })).subscribe((res: LawConservationConfiguration[]) => {
        this.newConfiguration = new LawConservationConfiguration();
        this.updateElements(res);
      });
    }
  }

  private copyElement(element: LawConservationConfiguration) {
    this.selectedConfiguration.id = element.id;
    this.selectedConfiguration.name = element.name;
    this.selectedConfiguration.sphereId = element.sphereId;
    this.selectedConfiguration.userId = element.userId;
    this.selectedConfiguration.bucketId = element.bucketId;
    this.selectedConfiguration.sendingFrequency = element.sendingFrequency;
    this.selectedConfiguration.fiscalYear = element.fiscalYear;
    this.selectedConfiguration.startSending = element.startSending;
    this.selectedConfiguration.date = element.date;
    this.selectedConfiguration.preservationSystem = element.preservationSystem;
    this.selectedConfiguration.alias = element.alias;
    this.selectedConfiguration.pin = element.pin;
    this.selectedConfiguration.flagValid = element.flagValid;
    this.selectedConfiguration.allowDelete = element.allowDelete;
    this.selectedConfiguration.allowModify = element.allowModify;
  }

  optionINFOCERT(): string {
    return ServiceTypeEnum.INFOCERT;
  }

  private sendingFrequencyTypeEnumToString(sendingFrequencyTypeEnum: string) {
    if (SendingFrequencyTypeEnum.MONTHLY === sendingFrequencyTypeEnum) {
      return this.translateSrv.instant('documents.law.modal.frequency.every-month');
    } else if (SendingFrequencyTypeEnum.TWO_MONTH === sendingFrequencyTypeEnum) {
      return this.translateSrv.instant('documents.law.modal.frequency.every-two-month');
    } else if (SendingFrequencyTypeEnum.QUARTER === sendingFrequencyTypeEnum) {
      return this.translateSrv.instant('documents.law.modal.frequency.every-three-month');
    } else if (SendingFrequencyTypeEnum.FOURTH === sendingFrequencyTypeEnum) {
      return this.translateSrv.instant('documents.law.modal.frequency.every-four-month');
    } else if (SendingFrequencyTypeEnum.HALF_YEAR === sendingFrequencyTypeEnum) {
      return this.translateSrv.instant('documents.law.modal.frequency.every-six-month');
    } else if (SendingFrequencyTypeEnum.ANNUAL === sendingFrequencyTypeEnum) {
      return this.translateSrv.instant('documents.law.modal.frequency.annual');
    }

    return '';
  }

  getFrequency(element: LawConservationConfiguration) {
    return this.sendingFrequencyTypeEnumToString(element.sendingFrequency);
  }

  private createFrequency(value: SendingFrequencyTypeEnum) {
    const frequency = new LawConservationFrequency();
    frequency.value = value;
    frequency.name = this.sendingFrequencyTypeEnumToString(value);
    return frequency;
  }

  initFrequency() {
    this.frequencyList = [];
    this.frequencyList.push(this.createFrequency(SendingFrequencyTypeEnum.MONTHLY));
    this.frequencyList.push(this.createFrequency(SendingFrequencyTypeEnum.TWO_MONTH));
    this.frequencyList.push(this.createFrequency(SendingFrequencyTypeEnum.QUARTER));
    this.frequencyList.push(this.createFrequency(SendingFrequencyTypeEnum.FOURTH));
    this.frequencyList.push(this.createFrequency(SendingFrequencyTypeEnum.HALF_YEAR));
    this.frequencyList.push(this.createFrequency(SendingFrequencyTypeEnum.ANNUAL));
  }

  setNgbSendingDate(date: NgbDate) {
    if (date == null) {
      this.selectedConfiguration.startSending = null;
      this.newConfiguration.startSending = null;
      return '';
    }

    if (date.year >= 1970 && validateDate(date)) {
      this.errorSendingDate = '';
      this.selectedConfiguration.startSending = Date.UTC(date.year, date.month - 1, date.day);
      this.newConfiguration.startSending = Date.UTC(date.year, date.month - 1, date.day);
    } else {
      this.errorSendingDate = this.translateSrv.instant('generic.message.date-format-error');
    }
  }

  convertStatus(status: any): string {
    if (status && status.currentTaskStatus) {
      return this.translateSrv.instant("digitalpreservation.config.status." + status.currentTaskStatus);
    }
    return '';
  }

  isInProgressStatus(row: any): boolean {
    if (row.taskStatus && row.taskStatus?.currentTaskStatus) {
      return row.taskStatus.currentTaskStatus === "IN_PROGRESS";
    }
    return false;//row.taskStatus?.currentTaskStatus?.equalsIgnoreCase("init");
  }

  launchConf(conf: any): void {
    // Aprire modale per conferma
    // aggiornare elemento della lista tramite funzione update

    this.refreshList();
  }

  getNextExecution(row: LawConservationConfiguration): Date {
    let output = null;
    if (row && row.sendingFrequency && row.lastExecution && typeof row.lastExecution === 'number') {
      let date = new Date(row.lastExecution);
      switch (row.sendingFrequency) {
        case SendingFrequencyTypeEnum.MONTHLY:
          output = date.setMonth(date.getMonth() + 1);
          break;
        case SendingFrequencyTypeEnum.TWO_MONTH:
          output = date.setMonth(date.getMonth() + 2);
          break;
        case SendingFrequencyTypeEnum.QUARTER:
          output = date.setMonth(date.getMonth() + 3);

          break;
        case SendingFrequencyTypeEnum.FOURTH:
          output = date.setMonth(date.getMonth() + 4);
          break;
        case SendingFrequencyTypeEnum.HALF_YEAR:
          output = date.setMonth(date.getMonth() + 6);
          break;
        case SendingFrequencyTypeEnum.ANNUAL:
          output = date.setMonth(date.getMonth() + 12);
          break;

        default:
          break;
      }
    }
    return output;
  }


}
