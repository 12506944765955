<div class="page-container" *ngIf="currentSphereId">
    <app-documents-breadcrumbs [path]="path" (navigate)="backElement($event)"></app-documents-breadcrumbs>

    <div class="year-selector-wrapper">
        <button class="tab-button" *ngIf="yearsList.length>yearsInterval" [disabled]="checkCurrentIndex()==-1" (click)="onPrevFiscalYearsClick()">
            <img [ngClass]="{'tab-button-image-disabled': checkCurrentIndex()==-1 }" class="tab-button-image"
                src="/assets/images/icons/left-chevron-circle-fill.svg" /></button>
        <div *ngFor="let year of yearsList | slice:yearsSliceFirstIndex:yearsSliceLastIndex" [ngClass]="{'year-selector': true, 'selected': year == selectedYear}" (click)="selectYear(year)">
            {{year.year}}
        </div>
        <button class="tab-button" *ngIf="yearsList.length>yearsInterval" [disabled]="checkCurrentIndex()==1" (click)="onNextFiscalYearsClick()">
            <img [ngClass]="{'tab-button-image-disabled': checkCurrentIndex()==1 }" class="tab-button-image"
                src="/assets/images/icons/right-chevron-circle-fill.svg" /> </button>
    </div>

    <ng-container *ngIf="selectedYear">
        <div class="d-flex flex-row mb-4 align-items-end">
            <div style="max-width: 300px;">
                <search-input [placeholder]="'generic.field.search'|translate" [label]="'documents.component.search-document'|translate" [(ngModel)]="searchTerm" (ngModelChange)="onSearch()"></search-input>
            </div>
            <div style="width: 300px; margin-left: 20px; z-index: 100;">
                <label class="form-input-label">{{'documents.component.select-tag'|translate}}</label>
                <ng-select id="tag" bindLabel="code" appendTo="body" [(ngModel)]="tagCodes" (change)="onSearch()" (remove)="onSearch()" multiple="true" >
                    <ng-option [value]="tag.id" *ngFor="let tag of tagData">
                        {{tag.code}}
                    </ng-option>
                </ng-select>
            </div>
            <div class="ml-auto" *ngIf="currentDirectoryExists">
                <ng-container *ngIf="canCreateFolder$| async">
                    <button class="btn btn-ghost ml-auto align-self-end text-nowrap" (click)="openFolderCreator()" style="min-width: 125px">
                        <img src="/assets/images/icons/documents/new_folder.svg"
                            [alt]="'documents.law.new-folder.create'|translate" class="btn-ghost-icon">
                        <span class="d-inline-block">
                            {{'documents.component.directory'|translate}}
                        </span>
                    </button>
                    <button class="btn btn-ghost ml-auto align-self-end text-nowrap" (click)="openFileUploader()" style="min-width: 125px" *ngIf="!isRoot">
                        <img src="/assets/images/icons/documents/new_document.svg"
                            [alt]="'documents.uploader.upload-document'|translate" class="btn-ghost-icon">
                        <span class="d-inline-block">
                            {{'documents.component.document'|translate}}
                        </span>
                    </button>
                </ng-container>
                <ng-container *ngIf="hasFilters">
                    <button class="btn btn-ghost ml-auto align-self-end text-nowrap" (click)="clearFilters()" style="min-width: 125px">
                        <img src="/assets/images/icons/clear-light.svg" [alt]="'generic.filters.clear'|translate"
                            class="btn-ghost-icon">
                        <span class="d-inline-block">
                            {{'generic.filters.clear'|translate}}
                        </span>
                    </button>
                </ng-container>
                <button class="btn btn-ghost ml-auto align-self-end text-nowrap" (click)="openFilterSearch()">
                    <img src="/assets/images/icons/ricerca_avanzata.svg"
                        [alt]="'documents.component.advanced-search'|translate" class="btn-ghost-icon">
                    <span class="d-inline-block">
                        {{'documents.component.advanced-search'|translate}}
                    </span>
                </button>
            </div>
        </div>

        <list-toolbar *ngIf="openingZipFile && extractingZipFile">
            <ng-container toolbar-actions>
                <fa-icon [icon]="faCircleNotch" [spin]="true" size="lg"></fa-icon>
            </ng-container>
            <ng-container toolbar-text>
                {{'documents.component.extraction'|translate}}
            </ng-container>
        </list-toolbar>

        <list-toolbar *ngIf="anyMetadataIncomplete && !advancedFilters">
            <ng-container toolbar-actions>
                <img src="/assets/images/icons/documents/warning_red.svg" [alt]="'documents.component.missing-data'|translate" placement="top" [ngbTooltip]="'documents.component.missing-data'|translate">
            </ng-container>
            <ng-container toolbar-text>
                <span *ngIf="metadataIncompleteCount == 1"> {{'generic.simple-word.there-is'|translate}}
                    {{metadataIncompleteCount}} {{'documents.component.document-missing-data-single'|translate}} </span>
                <span *ngIf="metadataIncompleteCount > 1"> {{'generic.simple-word.there-are'|translate}}
                    {{metadataIncompleteCount}}
                    {{'documents.component.document-missing-data'|translate}} </span>
                <ng-container *ngIf="!onlyMetadataComplete">
                    <!-- <a href="javascript:;" *ngIf="metadataIncompleteCount == 1" (click)="filterMetadataIncomplete()">{{'documents.component.select'|translate}}
                        {{metadataIncompleteCount}} {{'documents.component.select-document-single'|translate}}</a> -->
                    <a href="javascript:;" *ngIf="metadataIncompleteCount > 0" (click)="filterMetadataIncomplete()">{{'documents.component.select'|translate}}
                        {{'documents.component.select-document'|translate}}</a>
                </ng-container>
                <ng-container *ngIf="onlyMetadataComplete">
                    <a href="javascript:;" (click)="resetMetadataIncompleteFilter()">{{'documents.component.show-elements'|translate}}</a>
                </ng-container>
            </ng-container>
        </list-toolbar>

        <list-toolbar *ngIf="dmsCartService.isNotEmpty()">
            <ng-container toolbar-actions>
                <img src="/assets/images/icons/documents/share-dark.svg" [alt]="'generic.action.share'|translate" placement="top" [ngbTooltip]="'generic.action.share'|translate">
            </ng-container>
            <ng-container toolbar-text>
                <span *ngIf="dmsCartService.count() == 1">
                    {{'generic.action.you-selected'|translate}} {{dmsCartService.count()}}
                    {{'documents.component.sharing-element-single'|translate}}.
                </span>
                <span *ngIf="dmsCartService.count() > 1">
                    {{'generic.action.you-selected'|translate}} {{dmsCartService.count()}}
                    {{'documents.component.sharing-element'|translate}}
                </span>
                <a href="javascript:;" (click)="onConfirmSharing()">{{'documents.component.share-now'|translate}}</a>
                <span class="sep"> | </span>
                <a href="javascript:;" (click)="onCancelSharing()">{{'documents.component.cancel-selection'|translate}}</a>
            </ng-container>
        </list-toolbar>

        <list-toolbar *ngIf="dmsCartMoveService.isNotEmpty()">
            <ng-container toolbar-actions>
                <img src="/assets/images/icons/documents/toolbar/move-hover.svg" [alt]="'generic.action.move'|translate" placement="top" [ngbTooltip]="'generic.action.move'|translate">
            </ng-container>
            <ng-container toolbar-text>
                <span *ngIf="dmsCartMoveService.count() == 1">
                    {{'generic.action.you-selected'|translate}} {{dmsCartMoveService.count()}}
                    {{'documents.componentmove-element-single'|translate}}
                </span>
                <span *ngIf="dmsCartMoveService.count() > 1">
                    {{'generic.action.you-selected'|translate}} {{dmsCartMoveService.count()}}
                    {{'documents.componentmove-element'|translate}}
                </span>
                <ng-container *ngIf="canCreateFolder$ | async">
                    <a href="javascript:;" (click)="onConfirmMoving()">{{'documents.component.move-here'|translate}}</a>
                    <span class="sep"> | </span>
                </ng-container>
                <a href="javascript:;" (click)="onCancelMoving()">{{'documents.component.cancel-selection'|translate}}</a>
            </ng-container>
        </list-toolbar>

        <app-connection-toolbar [connectionType]="connectionType" [canCreateFolder]="canCreateFolder$|async" (confirm)="connectionModal.open()" (cancel)="onCancelConnection()"></app-connection-toolbar>

        <app-attachment-toolbar (confirm)="openAttachmentsUploader()" (cancel)="onAttachmentCanceled()">
        </app-attachment-toolbar>

        <list-toolbar *ngIf="showFilesDimensionWarning">
            <ng-container toolbar-actions>
                <img src="/assets/images/icons/documents/warning_red.svg">
            </ng-container>
            <ng-container toolbar-text>
                <span>{{'documents.component.download-not-permitted'|translate}}</span>
            </ng-container>
        </list-toolbar>

        <app-documents-toolbar [selectedElements]="selectedResults" [selected]="selectedCount" [isMoving]="startedMoving" [isSharing]="startedSharing" [isConnecting]="startedConnecting" [isAttaching]="startedAttaching" [selectedDimension]="selectedDimension" [totalElements]="totalElements$|async"
            [total]="totalCount" [hasFolders]="hasFoldersInSelection" [onlyOneFileSelected]="onlyOneFileSelected" [canDownloadDossier]="canDownloadDossier" (view)="openElementDetails()" (allSelected)="selectAllRows($event)" (allGlobalSelectedForExport)="selectAllGlobalForExport()" (deselectAllElements)="deselctAllElements()" (lawConservation)="openLawConservation()"
            (noLawConservation)="openNoLawConservation()" (favorite)="openFavorite()" (move)="startMoving()" (connect)="startConnecting()" (attach)="startAttaching()" (download)="openDownloadModal()" (downloadDossier)="openDownloadDossierModal()" (newFileVersion)="openFileUploaderNewVersion(selectedResults[0])"
            (delete)="openDeleteModal()" (share)="startSharing()" (tag)="openTagModal()" (exportCSV)="openExportCSVModal()"></app-documents-toolbar>

        <div class="card" *ngIf="data && viewChecked">
            <div class="card-body card-table sticky-container" style="overflow: visible">
                <data-table #dataTable [dataSource]="getData()" [columns]="columns" [customColumnDefs]="customColumns" [customColumns]="customColumnsActive" [enableSelect]="true" [allSelected]="totalCount && selectedCount == totalCount" (sort)="onSort($event)" (select)="selectRows($event)"
                    (selectAll)="selectAllRows($event)">

                    <ng-container cdkColumnDef="type" sticky>
                        <th cdk-header-cell *cdkHeaderCellDef scope="col" style="width: 30px;"></th>
                        <td cdk-cell *cdkCellDef="let row" (click)="this.openElement(row)" class="pointer">
                            <div class="outcome-true mr-0">
                                <img src="{{getElementTypeImageCell(row)}}" alt="{{getElementTypeAltCell(row)}}">
                            </div>
                        </td>
                    </ng-container>

                    <ng-container cdkColumnDef="name" sticky>
                        <th cdk-header-cell *cdkHeaderCellDef scope="col" sortable="name" (sort)="onSort($event)">
                            {{'generic.field.name'|translate}}
                            <pwc-sorting-arrows [selected]="sortType == 'name'" [direction]="sortReverse">
                            </pwc-sorting-arrows>
                        </th>
                        <td cdk-cell *cdkCellDef="let row" (dblclick)="this.openElement(row)" class="cdk-column-name pointer">
                            <span *ngIf="!searchInTree">{{(row.name ? row.name : '')}}</span>
                            <span *ngIf="searchInTree">{{(row.name ? row.name : '')}}</span>

                            <a class="law-conservation-badge" *ngIf="labelLawConservationShow(row) && isLawConservationEnabled(row) && !isLawConservationBadgeModifyHidden(row)" (click)="this.lawConservationModal.openId(row)">
                                <div class="badge">{{'documents.component.law-conserved'|translate}}<img src="/assets/images/icons/edit-white.svg"></div>
                            </a>
                            <a class="law-conservation-badge" style="cursor: default" *ngIf="labelLawConservationShow(row) && !isLawConservationEnabled(row)">
                                <div class="badge">{{'documents.component.law-conserved'|translate}}</div>
                            </a>

                            <a class="law-conservation-badge" *ngIf="labelLawConservationParentShow(row) && isLawConservationEnabled(row) && !isLawConservationBadgeModifyHidden(row)" (click)="this.lawConservationModal.openId(row)">
                                <div class="badge">{{'documents.component.law-conserved'|translate}}<img src="/assets/images/icons/edit-white.svg"></div>
                            </a>
                            <a class="law-conservation-badge" style="cursor: default" *ngIf="labelLawConservationParentShow(row) && !isLawConservationEnabled(row)">
                                <div class="badge">{{'documents.component.law-conserved'|translate}}</div>
                            </a>

                            <a class="law-conservation-badge" *ngIf="isFavorite(row)">
                                <div class="badge">{{'documents.component.favorite'|translate}}</div>
                            </a>
                            <span *ngIf="searchInTree" class="path"><br>{{row.path ? formatPath(row.path) : ''}}</span>

                            <fa-icon [icon]="faCircleNotch" [spin]="true" *ngIf="isLoading(row)" style="margin-left:1rem;"></fa-icon>

                        </td>
                    </ng-container>

                    <ng-container cdkColumnDef="documentDate">
                        <th cdk-header-cell *cdkHeaderCellDef scope="col" sortable="document_date" (sort)="onSort($event)">
                            {{'documents.component.document_date'|translate}}
                            <pwc-sorting-arrows [selected]="sortType == 'document_date'" [direction]="sortReverse">
                            </pwc-sorting-arrows>
                        </th>
                        <td cdk-cell *cdkCellDef="let row">
                            {{row.documentDate|date:'dd/MM/yyyy'}}
                        </td>
                    </ng-container>

                    <ng-container cdkColumnDef="createdDate">
                        <th cdk-header-cell *cdkHeaderCellDef scope="col" sortable="created_date" (sort)="onSort($event)">
                            {{'documents.component.created_date'|translate}}
                            <pwc-sorting-arrows [selected]="sortType == 'created_date'" [direction]="sortReverse">
                            </pwc-sorting-arrows>
                        </th>
                        <td cdk-cell *cdkCellDef="let row">
                            {{row.createdDate|date:'dd/MM/yyyy'}}
                        </td>
                    </ng-container>

                    <ng-container *ngFor="let column of customMetaColumnsActive" [cdkColumnDef]="column">
                        <th cdk-header-cell *cdkHeaderCellDef scope="col">{{column}}</th>
                        <td cdk-cell *cdkCellDef="let row">
                            {{getMetadataValue(row.metadata, column)}}
                        </td>
                    </ng-container>

                    <ng-container cdkColumnDef="allVisibleMetadata">
                        <th cdk-header-cell *cdkHeaderCellDef scope="col" width="700">
                            {{'documents.component.metadata'|translate}}</th>
                        <td cdk-cell *cdkCellDef="let row">
                            {{getAllVisibleMetadata(row)}}
                        </td>
                    </ng-container>

                    <ng-container cdkColumnDef="details" stickyEnd>
                        <th cdk-header-cell *cdkHeaderCellDef scope="col" style="width: 115px;">
                            {{'documents.component.info'|translate}}</th>
                        <td cdk-cell *cdkCellDef="let row" class="text-right force-zindex-2">
                            <div *ngIf="row.isShared" [ngbPopover]="sharingInfo" placement="top" class="document-info-icon" [popoverTitle]="'documents.metadata.sharing'|translate" triggers="mouseenter:mouseleave" (mouseenter)="getSharingPopupDescription(row)" style="z-index:99999;display:inline-block;">
                                <img src="/assets/images/icons/documents/shared.svg" [alt]="'documents.component.shared'|translate" class="document-info-icon">
                            </div>
                            <img src="/assets/images/icons/documents/warning_red.svg" [alt]="'documents.component.missing-data'|translate" [ngbTooltip]="'documents.component.missing-data'|translate" placement="left" *ngIf="isFile(row) && !row.metadataComplete && !hasIconLawConservation(row)"
                                class="document-info-icon">
                            <img src="/assets/images/icons/documents/law-conservation/conservato.svg" [alt]="'documents.component.law-conserved'|translate" [ngbTooltip]="'documents.component.law-conserved'|translate" placement="left" *ngIf="isFile(row) && hasIconLawConservation(row)"
                                class="document-info-icon">
                            <img src="/assets/images/icons/documents/law-conservation/nonconservato_daconservare.svg" [alt]="'documents.law.modal.send-now'|translate" class="document-info-icon" (click)="sendLawConservation(row)" [ngbTooltip]="'documents.component.send-conservation-now'|translate"
                                placement="left" triggers="mouseenter:mouseleave" *ngIf="isFile(row) && hasIconLawConservationToSend(row) && isLawConservationEnabled(row) && !hasIconLawConservation(row)">
                            <img src="/assets/images/icons/documents/law-conservation/nonconservato_daconservare.svg" [alt]="'documents.component.to-be-conserved'|translate" class="document-info-icon" [ngbTooltip]="'documents.component.to-be-conserved'|translate" placement="left"
                                triggers="mouseenter:mouseleave" *ngIf="isFile(row) && hasIconLawConservationToSend(row) && !isLawConservationEnabled(row) && !hasIconLawConservation(row)">
                            <img src="/assets/images/icons/documents/law-conservation/nonconservato_daconservare.svg" [alt]="'generic.message.sending'|translate" [ngbTooltip]="'generic.message.sending'|translate" placement="left" *ngIf="isFile(row) && hasIconLawConservationRunning(row)"
                                class="document-info-icon">
                            <img src="/assets/images/icons/documents/law-conservation/nonconservare.svg" [alt]="'documents.component.no-conserved-law'|translate" [ngbTooltip]="'documents.component.no-conserved-law'|translate" placement="left" *ngIf="isFile(row) && hasIconNoLawConservation(row)"
                                class="document-info-icon">
                            <img src="/assets/images/icons/documents/law-conservation/conservato_anomalia.svg" [alt]="'documents.component.conservation-error'|translate" [ngbPopover]="ErrorPopoverLawConservation" #popover="ngbPopover" triggers="manual" closeDelay="750" [popoverTitle]="'documents.component.error-title'|translate"
                                placement="left" container="body" (click)="sendLawConservation(row)" (mouseenter)="errorPopoverLawConservationEnter(popover)" (mouseleave)="errorPopoverLawConservationLeave(popover)" *ngIf="isFile(row) && hasIconLawConservationError(row) && isLawConservationEnabled(row)  && !hasIconLawConservation(row)"
                                class="document-info-icon">
                            <ng-template #ErrorPopoverLawConservation>
                                <div (mouseenter)="errorPopoverLawConservationHtmlEnter()" (mouseleave)="errorPopoverLawConservationHtmlExit()">
                                    {{errorMessageLawConservation(row)}}
                                    <div *ngIf="isErroreConfigurazione(row)">
                                        {{'documents.component.check'|translate}} <a routerLink="/archive-configuration">{{'documents.component.configuration'|translate}}</a>
                                    </div>
                                </div>
                            </ng-template>
                            <img src="/assets/images/icons/documents/law-conservation/conservato_anomalia.svg" [alt]="'documents.component.conservation-error'|translate" [ngbTooltip]="'documents.component.conservation-error'|translate" placement="left" triggers="mouseenter:mouseleave"
                                *ngIf="isFile(row) && hasIconLawConservationError(row) && !isLawConservationEnabled(row)" class="document-info-icon">
                            <img src="/assets/images/icons/documents/law-conservation/conservato_anomalia.svg" [alt]="'documents.component.missing-metadata'|translate" [ngbTooltip]="'documents.component.missing-metadata'|translate" placement="left" *ngIf="isFile(row) && hasIconLawConservationNoMetadata(row)"
                                class="document-info-icon">
                            <img src="/assets/images/icons/documents/law-conservation/conservato_rettifica.svg" [alt]="'documents.component.last-version-not-conserved'|translate" [ngbTooltip]="'documents.component.last-version-not-conserved'|translate" placement="left" *ngIf="isFile(row) && hasIconLawConservationNewVersionNotSent(row) && isLawConservationEnabled(row)"
                                (click)="sendLawConservation(row)" class="document-info-icon">
                            <img src="/assets/images/icons/documents/law-conservation/conservato_rettifica.svg" [alt]="'documents.component.last-version-not-conserved'|translate" [ngbTooltip]="'documents.component.last-version-not-conserved'|translate" placement="left" *ngIf="isFile(row) && hasIconLawConservationNewVersionNotSent(row) && !isLawConservationEnabled(row)"
                                class="document-info-icon">

                            <!-- <pwc-icon name="view" [ngbTooltip]="'generic.action.open-zip'|translate" placement="left" *ngIf="isFileZip(row)" (click)="openFileZip(row)" class="document-info-icon">
                            </pwc-icon> -->
                        </td>
                    </ng-container>

                    <ng-container cdkColumnDef="info" stickyEnd>
                        <th cdk-header-cell *cdkHeaderCellDef scope="col" style="width: 30px"></th>
                        <td cdk-cell *cdkCellDef="let row">
                            <button placement="top" [ngbTooltip]="'generic.field.info'|translate" type="button" class="btn btn-primary btn-sm btn-action-icon" (click)="onShowInfo(row)">
                                <pwc-icon name="info"></pwc-icon>
                            </button>
                        </td>
                    </ng-container>
                </data-table>

                <app-table-content-loader *ngIf="loadingDocuments$ | async"></app-table-content-loader>
            </div>
        </div>

        <div class="my-4">
            <pwc-pagination *ngIf="pagination" [page]="pagination.number + 1" [pageSize]="pagination.size" [collectionSize]="pagination.totalElements" (pageChange)="onPageChange($event)"></pwc-pagination>
        </div>

    </ng-container>
</div>

<app-new-folder-modal #newFolderModal [destinationDirectory]="currentDirectory" [fiscalYear]="selectedYear?.year" [sphereId]="currentSphereId" (created)="refreshList()"></app-new-folder-modal>

<app-law-conservation-modal #lawConservationModal [selectedElements]="selectedResults" [fiscalYear]="selectedYear?.year" [sphereId]="currentSphereId" (saved)="refreshList()"></app-law-conservation-modal>

<app-download-modal #downloadModal (downloadStandard)="beingStandardDownload()" (downloadConservation)="beginConservationDownload()"></app-download-modal>

<!--<app-download-modal #downloadDossierModal (downloadStandard)="beginDossierDownload()"></app-download-modal>-->

<app-document-metadata #infoModal [dmsElement]="infoElement" [path]="path" [selectedYear]="selectedYear" (openFileDetailEvent)="openElementDetail(infoElement.id, $event)" (openElementPreview)="openElementPreview($event)" (downloadFileDetailEvent)="openDownloadHistoryModal($event)"
    (tagDeleted)="refreshList()"  (connectionDeleted)="refreshList()" (closed)="onHideInfo()">
</app-document-metadata>

<pwc-modal #directoryNotFoundModal id="directory-not-found" (closed)="resetPathToRoot()">
    <div modal-title>{{'documents.component.path-not-found-title'|translate}}</div>
    <div modal-body>
        {{'documents.component.path-not-found-body-1'|translate}}
        <span *ngFor="let dir of path">/{{dir.name}}</span> {{'documents.component.path-not-found-body-2'|translate}}
    </div>
    <div modal-footer class="text-right">
        <button type="button" class="btn btn-secondary" (click)="directoryNotFoundModal.close()">
            {{'generic.action.close'|translate}}
        </button>
    </div>
</pwc-modal>

<app-document-detail #documentDetail [currentFiscalYear]="selectedYear?.year" [currentSphereId]="currentSphereId" [generalCounselDmsSphere]="generalCounselDmsSphere" [currentFolderId]="currentDirectory" (closed)="detailClosed()" (addNewVersion)="openFileUploaderNewVersion($event)">
</app-document-detail>

<app-documents-uploader #uploadModal id="upload-documents" [destinationDirectory]="currentDirectory" (closed)="forceCloseDetail()" [fiscalYear]="selectedYear?.year">
</app-documents-uploader>

<app-document-confirm-modal #confirmModal (positiveButtonClick)="confirmDeleteModal()" [disabled]="deleting" documentConfirmModalId="document-modal-id" message="{{deleteMessage}}" [selectedResults]="selectedResults" [positiveButtonText]="'generic.action.confirm-caps'|translate"
    [negativeButtonText]="'generic.action.cancel-caps'|translate" [title]="'generic.action.delete.label'|translate" [sphereId]="currentSphereId" [selectedYear]="selectedYear"></app-document-confirm-modal>

<app-document-confirm-law-conservation-send-now-modal #lawConservationSendNowModal (positiveButtonClick)="confirmLawConservationSendNowModal()" documentConfirmLawConservationSendNowModalId="document-law-conservation-send-now-modal-id" message="{{lawConservationSendNowMessage}}"
    [positiveButtonText]="'generic.action.confirm-caps'|translate" [negativeButtonText]="'generic.action.cancel-caps'|translate" [title]="'documents.component.send-conservation-now'|translate" [sphereId]="currentSphereId" [selectedYear]="selectedYear">
</app-document-confirm-law-conservation-send-now-modal>

<app-document-confirm-no-conservation-modal #noLawConservationModal (positiveButtonClick)="confirmNoLawConservationModal()" documentConfirmNoConservationModalId="document-no-law-conservation-modal-id" message="{{noLawConservationMessage}}" [selectedResults]="selectedResults"
    [positiveButtonText]="'generic.action.confirm-caps'|translate" [negativeButtonText]="'generic.action.cancel-caps'|translate" [title]="'generic.action.no-conserve-to-law'|translate" [sphereId]="currentSphereId" [selectedYear]="selectedYear">
</app-document-confirm-no-conservation-modal>

<app-share-modal #shareModal [elements]="dmsCartService.list$ | async" (deselect)="selectRows($event.id)" [selectedYear]="selectedYear" (shared)="onShareConfirmed()"></app-share-modal>

<app-start-sharing-modal #startSharingModal [elements]="dmsCartService.list$ | async" (cancel)="onCancelSharing()" (continue)="onContinueSharing()" (confirm)="onConfirmSharing()"></app-start-sharing-modal>

<app-start-connection-modal #startConnectionModal [elements]="connectionCartService.list$ | async" [connectionType]="connectionType" (cancel)="onCancelConnection()" (continue)="onContinueConnection()" (confirm)="onConfirmConnection()"></app-start-connection-modal>

<app-connection-modal #connectionModal [elements]="connectionCartService.list$ | async" [fiscalYear]="selectedYear" [directoryId]="currentDirectory" [connectionType]="connectionType" (canceled)="onCancelConnection()" (deselect)="selectRows($event.id)"
    (confirmed)="onConnectionConfirmed()"></app-connection-modal>

<app-connection-type-modal #connectionTypeModal [connectionType]="connectionType" (typeSelected)="onConnectionTypeSelected($event)"></app-connection-type-modal>

<pwc-modal #favoriteModal id="favoriteModal">
    <div modal-title>{{'documents.component.favourite-title'|translate}}</div>
    <div modal-body>
        {{'documents.component.favourite-body'|translate}}
    </div>
    <div modal-footer class="text-right">
        <button type="button" class="btn btn-danger" (click)="favoriteModal.close()">
            {{'generic.action.cancel'|translate}}
        </button>
        <button type="button" class="btn btn-success ml-2" (click)="confirmFavoriteModal()">
            {{'generic.action.confirm'|translate}}
        </button>
    </div>
</pwc-modal>

<pwc-modal #errorMoving id="errorMoving">
    <div modal-title>{{'documents.component.error-title'|translate}}</div>
    <div modal-body>
        {{'documents.component.error-body'|translate}}
    </div>
    <div modal-footer class="text-right">
        <button type="button" class="btn btn-secondary" (click)="errorMoving.close()">
            {{'generic.action.close'|translate}}
        </button>
    </div>
</pwc-modal>

<pwc-modal #shareShortcutAlert id="shareShortcutAlert">
    <div modal-title>{{'documents.component.error-title'|translate}}</div>
    <div modal-body>
        {{'documents.component.share-error-body'|translate}}
    </div>
    <div modal-footer class="text-right">
        <button type="button" class="btn btn-secondary" (click)="shareShortcutAlert.close()">
            {{'generic.action.close'|translate}}
        </button>
    </div>
</pwc-modal>

<app-add-tag-modal #addTagModal [sphereId]="currentSphereId" [elements]="selectedResults" (added)="onTagAdded()" [fiscalYear]="selectedYear"></app-add-tag-modal>

<app-export-csv-modal #addExportCSVModal (globalExportCsv)="globalExportCsv()" (globalExportRichCsv)="globalExportRichCsv()" [elements]="selectedResults" [isGlobal]="isGlobal" [connectionType]="connectionType" (typeExport)="onExportCSVTypeSelected($event)"></app-export-csv-modal>

<app-document-filter-modal #advancedSearchModal [sphereId]="currentSphereId" [fiscalYear]="selectedYear?.year" (searchEvent)="onSearchAdvanced($event)"></app-document-filter-modal>

<app-start-attachment-modal #startAttachmentModal [elements]="attachmentCartService.list$ | async" (cancel)="onAttachmentCanceled()" (confirm)="openAttachmentsUploader()"></app-start-attachment-modal>

<app-attachments-uploader #attachmentUploadModal (confirmed)="onUploadAttachmentsConfirmed()">
</app-attachments-uploader>

<app-attachment-modal #attachmentModal [connection]="attachmentConnection" (canceled)="onAttachmentCanceled()" (confirmed)="onAttachmentConfirmed()"></app-attachment-modal>

<ap-alert [(show)]="displayCsvInProgressAlert" [alertType]="'success'" [iconName]="'icon-confirmed-fill'">
    <span>{{'export.modal.toast-left'|translate}}</span><a routerLink="/exports">{{'export.modal.toast-hyperlink'|translate}}</a><span>{{'export.modal.toast-right'|translate}}</span>
</ap-alert>

<pwc-spinner [fullscreen]="true" name="documents-spinner" size="medium"></pwc-spinner>

<ng-template #sharingInfo>
    <div [innerHTML]="sharingPopupInfo$ | async"></div>
</ng-template>